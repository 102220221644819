import {Typography} from "@mui/material";
import React from "react";

const MarkdownComponents = {
    p(props) {
        const {node, ...rest} = props;
        return <Typography variant="body1" component="p" {...rest} />;
    },

    li(props) {
        const {node, ...rest} = props;
        return (
            <li {...rest}>
                <Typography variant="body1" component="span" {...rest} />
            </li>
        );
    },

    a(props) {
        const {node, ...rest} = props;
        return (
            <a {...rest} target="_blank" rel="noopener noreferrer">
                {props.children}
            </a>
        );
    },

    h1(props) {
        const {node, ...rest} = props;
        return <Typography variant="h1" component="h1" {...rest} />;
    },

    h2(props) {
        const {node, ...rest} = props;
        return <Typography variant="h2" component="h2" {...rest} />;
    },

    h3(props) {
        const {node, ...rest} = props;
        return <Typography variant="h3" component="h3" {...rest} />;
    },

    h4(props) {
        const {node, ...rest} = props;
        return <Typography variant="h4" component="h4" {...rest} />;
    },

    h5(props) {
        const {node, ...rest} = props;
        return <Typography variant="h5" component="h5" {...rest} />;
    },

    h6(props) {
        const {node, ...rest} = props;
        return <Typography variant="h6" component="h6" {...rest} />;
    },

    code(props) {
        const {node, inline, ...rest} = props;
        return inline ? (
            <Typography component="span" style={{ fontFamily: 'monospace', backgroundColor: '#f4f4f4', padding: '2px 4px' }} {...rest} />
        ) : (
            <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', overflowX: 'auto' }}>
                <code {...rest} />
            </pre>
        );
    },

    blockquote(props) {
        const {node, ...rest} = props;
        return (
            <blockquote style={{ borderLeft: '4px solid #ddd', marginLeft: 0, paddingLeft: '1em', color: '#666' }} {...rest} />
        );
    },

    img(props) {
        const {node, ...rest} = props;
        return (
            <img style={{ maxWidth: '100%', height: 'auto' }} alt={props.alt || ''} {...rest} />
        );
    },

    ul(props) {
        const {node, ...rest} = props;
        return <ul style={{ paddingLeft: '20px', marginTop: '0' }} {...rest} />;
    },

    ol(props) {
        const {node, ...rest} = props;
        return <ol style={{ paddingLeft: '20px', marginTop: '0' }} {...rest} />;
    },

    hr() {
        return <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />;
    }
};

export default MarkdownComponents;