// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#locusive-message-container {
    padding: 20px;
}

#locusive-message-container #locusive-send-button {
    color: #D642B5 !important;
}

#locusive-message-container #locusive-message-input {
    border: 1px solid #BCBAC7;
}

#locusive-message-container .MuiInputBase-root {
    border: 1px solid #BCBAC7;
    border-radius: 20px;
    min-height: 60px;
    color: #211F26;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageForm/MessageForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":["#locusive-message-container {\n    padding: 20px;\n}\n\n#locusive-message-container #locusive-send-button {\n    color: #D642B5 !important;\n}\n\n#locusive-message-container #locusive-message-input {\n    border: 1px solid #BCBAC7;\n}\n\n#locusive-message-container .MuiInputBase-root {\n    border: 1px solid #BCBAC7;\n    border-radius: 20px;\n    min-height: 60px;\n    color: #211F26;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
