import React, {useCallback, useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
// import Markdown from "react-markdown"
import ReactMarkdown from 'react-markdown';
import {GenericChatMessage} from "../../generated/api_service_pb";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import TurndownService from 'turndown';
import {
    AddDocumentCommand,
    FindCommand,
    HelpCommand,
    HelpText,
    InvalidAddDocumentUrlText,
    SlashCommandOptions,
    // SlashCommandOptionsList,
} from "../../utils/constants/SlashCommands"
import {
    ChannelInstanceIsValidMessage, CloseChatMessageWindow,
    ContextualRecommendationType,
    LoadingMessages
} from "../../utils/constants/Constants";
import EmbeddedAssistantApiClient from "../../service_clients/EmbeddedAssistantApiClient";
import MessageForm from "../MessageForm/MessageForm";
import {Box, CircularProgress, Drawer, IconButton, Typography} from "@mui/material";
import FlatButton from "../FlatButton/FlatButton";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkIcon from "@mui/icons-material/Link";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EventLogger from "../../service_clients/EventLogger";
import remarkGfm from 'remark-gfm';
import MarkdownComponents from "./MarkdownComponents";
import styles from "./EmbeddedAssistantChat.module.css";


function CreateGenericChatMessage(message, role, ...additionalProps) {
    let genericChatMessage = new GenericChatMessage();
    genericChatMessage.setMessage(message);
    genericChatMessage.setRole(role);

    let timestamp = new Timestamp();
    timestamp.fromDate(new Date());
    genericChatMessage.setTimestamp(timestamp);

    Object.assign(genericChatMessage, ...additionalProps);
    return genericChatMessage
}


const EmbeddedAssistantChat = ({channelInstanceId, debugMode}) => {
    // Constants
    const UserRole = "user";
    const AssistantRole = "assistant";
    const LocusiveLogoUrl = "https://storage.googleapis.com/locusive-resource-webapp/icon-small.png"
    const LocusiveLoadingGifUrl = "https://storage.googleapis.com/locusive-resource-webapp/loading.gif";

    const StatusMessageType = "status";
    const chatRef = useRef(null);

    const [messages, setMessages] = useState([]);
    const [loadingFlagsForButtons, setLoadingFlagsForButtons] = useState({});
    const [summaryOfProcessMessages, setSummaryOfProcessMessages] = useState({});
    const [currentSummaryOfProcessMessage, setCurrentSummaryOfProcessMessage] = useState(null);
    const [disableMessages, setDisableMessages] = useState(false);
    const [contextualRecommendations, setContextualRecommendations] = useState([]);
    const [isBannerClosed, setIsBannerClosed] = useState(false);
    const [isValidDomain, setIsValidDomain] = useState(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [pageContent, setPageContent] = useState(null);

    useEffect(() => {
        if (debugMode) {
            console.log("Running in debug mode");
            console.log("Channel Instance ID: " + channelInstanceId + "\nPage URL: " + currentUrl);
        }

        EmbeddedAssistantApiClient.getContextualRecommendations(channelInstanceId, currentUrl, debugMode, (contextualRecommendations) => {
            if (!contextualRecommendations) {
                return;
            }
            setContextualRecommendations(contextualRecommendations);
        });

        EmbeddedAssistantApiClient.getValidDomainsForChannelInstance(channelInstanceId, debugMode, (validDomains) => {
            if (validDomains !== null && validDomains !== undefined && validDomains.length > 0 && !isPageUrlValidDomainForChannelInstance(validDomains)) {
                console.log("This assistant is not authorized to run on this url. Please check your allowed list of domains on your Locusive account to fix this error.");
                setIsValidDomain(false);
                return;
            }

            setIsValidDomain(true);
            dispatchEvent(ChannelInstanceIsValidMessage);
        });
    }, []);

    // TODO: Update this and the code above so we don't need to make two subsequent calls to the API to get both pieces of data
    useEffect(() => {
        if (!isValidDomain) {
            return;
        }

        EmbeddedAssistantApiClient.getChannelInstance(channelInstanceId, (channelInstance) => {
            if (channelInstance && channelInstance.getShouldsendpagecontent()) {
                collectPageContentAsMarkdown();
            }
        });
    }, [isValidDomain]);

    function collectPageContentAsMarkdown() {
        const contentHtml = document.querySelector('main')?.innerHTML || document.body.innerHTML;
        const documentTitle = document.title;
        const turndownService = new TurndownService({
            headingStyle: 'atx',
            codeBlockStyle: 'fenced',
        });

        const parser = new DOMParser();
        const doc = parser.parseFromString(contentHtml, 'text/html');
        const unwantedTags = doc.querySelectorAll('script, style, meta, link, noscript');
        unwantedTags.forEach(tag => tag.remove());
        let markdown = turndownService.turndown(doc.body.innerHTML);
        if (documentTitle) {
            markdown = `# Page Title: ${documentTitle}\n\n${markdown}`;
        }
        setPageContent(markdown);
    }

    function dispatchEvent(action) {
        const event = new CustomEvent('LocusiveAssistantEvent', {
            detail: {
                action: action,
            }
        });
        window.dispatchEvent(event);
    }

    function isPageUrlValidDomainForChannelInstance(validDomains) {
        if (validDomains.length === 0) {
            return true;
        }

        const url = new URL(currentUrl);
        const domain = url.hostname;
        return validDomains.includes(domain);
    }

    const addAgentExecutionResponseToMessages = useCallback(
        function addAgentExecutionResponseToMessages(agentExecutionResultMessage, updatedMessages) {

            const agentAnswerMessage = CreateGenericChatMessage(
                agentExecutionResultMessage?.getResponse(),
                AssistantRole,
                {id: uuidv4()}
            )

            if (agentExecutionResultMessage?.getHassummaryofprocess()) {

                agentAnswerMessage.seeDetailsMetadata = {
                    id: agentExecutionResultMessage?.getId(),
                    hasSummaryOfProcess: agentExecutionResultMessage?.getHassummaryofprocess()
                };

            }

            setDisableMessages(false)
            agentAnswerMessage.sources = agentExecutionResultMessage.getSourcesList();
            setMessages([...updatedMessages, agentAnswerMessage]);

        }, []);

    const processAgentExecutionResponse = useCallback(
        function processAgentExecutionResponse(agentExecutionResultMessage) {
            if (!agentExecutionResultMessage) {
                return;
            }

            // If the Agent's message has a status message id then it is a status message.  Update the status message.
            if (agentExecutionResultMessage?.getStatusmessageid()) {
                updateAgentStatusMessage(agentExecutionResultMessage);

                // If not a status message then assistant has an answer. Update the messages with the assistant answer
            }
            else {
                // Filter out status message (it has an id)
                const filteredMessages = messages.filter(message => !message.isStatusMessage);
                // Add the Agent's answer to the messages
                addAgentExecutionResponseToMessages(agentExecutionResultMessage, filteredMessages);
            }
        }, [messages, addAgentExecutionResponseToMessages])

    useEffect(() => {
        // Ensure chat interface is scrolled to the bottom (most recent message)
        if (chatRef?.current && messages?.length > 1) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }

        // Return if no messages
        if (!messages || messages?.length === 0) {
            return;
        }

        // return if the last message was from the assistant
        if (messages[messages?.length - 1].getRole() !== UserRole) {
            return;
        }

        // set a status message for a stream
        let statusMessage = createStatusMessage();
        addUserMessageToConversation(statusMessage)

        // Initiate stream with the Agent
        try {
            EmbeddedAssistantApiClient.agentExecutionRequest(
                messages,
                channelInstanceId,
                statusMessage.id,
                pageContent,
                debugMode,
                (agentExecutionResultMessage) => {
                    processAgentExecutionResponse(agentExecutionResultMessage);
                });
        }
        catch (e) {
            if (process.env.REACT_APP_ENVIRONMENT === "development" || debugMode) {
                console.log("Error occurred while sending the message to the API");
                console.error(e);
            }
        }

    }, [channelInstanceId, chatRef, messages, processAgentExecutionResponse]);


    // ////////////////////////////////////////////////
    // Messages and Slash Commands
    // ////////////////////////////////////////////////

    function addUserMessageToConversation(message) {
        let isStatusMessage;
        let isUserMessage;

        try {
            isStatusMessage = !!message?.getStatusmessageid();
        }
        catch {
            isStatusMessage = false
        }

        try {
            isUserMessage = message?.getRole() === UserRole
        }
        catch {
            isUserMessage = false
        }

        if (isStatusMessage || isUserMessage) {
            setDisableMessages(true)
        }
        else {
            setDisableMessages(false)
        }

        setMessages(prevMessages => [...prevMessages, message])
    }

    function sendMessage(messageContent) {
        if (!messageContent) {
            return;
        }

        const chatMessage = CreateGenericChatMessage(messageContent, UserRole, { id: uuidv4() });
        addUserMessageToConversation(chatMessage);
        EventLogger.sendMessage(channelInstanceId, messageContent, currentUrl);
    }

    function createStatusMessage() {
        let loadingMessage = LoadingMessages[Math.floor(Math.random() * LoadingMessages.length)];

        return CreateGenericChatMessage(
            loadingMessage,
            AssistantRole,
            {id: uuidv4(), isStatusMessage: true}
        )
    }

    function updateAgentStatusMessage(agentExecutionResultMessage) {
        setDisableMessages(true)
        setMessages(prevMessages => {
            return prevMessages.map(message => {
                // Find the status message in messages using the Agent's status message id
                if (message?.id && message?.id === agentExecutionResultMessage?.getStatusmessageid()) {
                    try {
                        // decode the Agent's status message
                        const trimmedBase64 = agentExecutionResultMessage?.getResponse()?.trim();
                        const decodedStatus = atob(trimmedBase64);
                        // Set the status message to the Agent's status message
                        message?.setMessage(decodedStatus);
                    } catch (e) {
                        console.error(e);
                    }
                }
                // return the message
                return message;
            });
        });

    }

    function handleAddDocumentCommand(documentUrl) {
        let urlIsValid;

        urlIsValid = isValidUrl(documentUrl)

        if (!urlIsValid) {
            const errorMessage = CreateGenericChatMessage(InvalidAddDocumentUrlText, AssistantRole)
            addUserMessageToConversation(errorMessage);
            return
        }

        EmbeddedAssistantApiClient.addDocumentToIndexRequest(channelInstanceId, documentUrl, (simpleSuccessResponse) => {
            const resultMessage = processAddDocumentToIndexResponse(simpleSuccessResponse)

            addUserMessageToConversation(resultMessage);

        });
    }

    function isValidUrl(url) {
        try {
            new URL(url);
            return true;
        } catch (_) {
            return false;
        }
    }

    function processAddDocumentToIndexResponse(simpleSuccessResponse) {

        let agentResponse;
        if (!simpleSuccessResponse) {
            agentResponse = "Something went wrong, please try again later."
        } else {
            agentResponse = simpleSuccessResponse?.getMessage()
                ? `Successfully added a new document`
                // TODO: figure out how to decode the message
                // ? `Successfully added a new document: ${simpleSuccessResponse?.getMessage()}`
                : `Successfully added a new document`
        }

        return CreateGenericChatMessage(agentResponse, AssistantRole)

    }

    function handleFindCommand(commandText) {
        EmbeddedAssistantApiClient.getTopDocumentMatchesRequest(channelInstanceId, commandText, (getTopDocumentMatchesResponse) => {
            const resultMessage = processGetTopDocumentMatchesResponse(getTopDocumentMatchesResponse)

            addUserMessageToConversation(resultMessage);
            // setDisableMessages(false)
        });
    }

    function processGetTopDocumentMatchesResponse(getTopDocumentMatchesResponse) {

        let resultMessage;
        if (!getTopDocumentMatchesResponse || getTopDocumentMatchesResponse?.length < 1) {
            resultMessage = "I was not able to find any matches at this time."
        } else {
            const findCommandMatches = getTopDocumentMatchesResponse?.getMatchesList()
            const topFiveMatches = findCommandMatches.slice(0, 5);

            resultMessage = "Below are the top 5 matches:\n" + topFiveMatches?.map((match, i) => {
                const documentName = match?.getName();
                const documentUri = match?.getUri();
                return `${i + 1}. [${documentName}](${documentUri})`;
            }).join("\n");
        }

        return CreateGenericChatMessage(resultMessage, AssistantRole)

    }

    function handleHelpCommand() {
        const helpMessage = CreateGenericChatMessage(HelpText, AssistantRole)
        addUserMessageToConversation(helpMessage);
        // setDisableMessages(false)
    }

    function handleSubmitMessage(message) {
        setDisableMessages(true)
        // Handle slash commands
        if (message?.startsWith("/")) {
            // Parse the command
            const parts = message?.split(" ");
            const command = parts[0];
            const commandText = parts?.slice(1)?.join(" ");
            // Route the command
            if (command === AddDocumentCommand) {
                handleAddDocumentCommand(commandText)
            }
            else if (command === FindCommand) {
                handleFindCommand(commandText)
            }
            else if (command === HelpCommand) {
                handleHelpCommand()
            }
        // Handle User messages
        }
        else {
            sendMessage(message);
        }
    }


    // ////////////////////////////////////////////////
    // Render
    // ////////////////////////////////////////////////

    function renderSeeDetailsButton(genericChatMessage) {
        if (!genericChatMessage.seeDetailsMetadata) {
            return null;
        }

        let buttonText = "See details";
        let agentExecutionResultId = genericChatMessage.seeDetailsMetadata.id;

        return <Box>
            <FlatButton
                startIcon={
                    loadingFlagsForButtons[agentExecutionResultId]
                        ? <CircularProgress size={16} style={{color: "#D642B5"}}/>
                        : <InfoOutlinedIcon size={16} />
                }
                variant="contained"
                disableElevation
                className={styles.locusive_flat_button}
                onClick={() => getAdditionalDetails(agentExecutionResultId)}
                disabled={loadingFlagsForButtons[agentExecutionResultId]}
            >
                {buttonText}
            </FlatButton>
        </Box>
    }

    const getAdditionalDetails = useCallback((agentExecutionResultId) => {
        if (summaryOfProcessMessages[agentExecutionResultId]) {
            setCurrentSummaryOfProcessMessage(summaryOfProcessMessages[agentExecutionResultId]);
            return;
        }

        setLoadingFlagsForButtons(prevFlags => ({...prevFlags, [agentExecutionResultId]: true}));

        EmbeddedAssistantApiClient.getSummaryOfProcessRequest(
            agentExecutionResultId,
            (summaryOfProcessMessage) => {
                setLoadingFlagsForButtons(prevFlags => ({...prevFlags, [agentExecutionResultId]: false}));
                if (!summaryOfProcessMessage) {
                    // TODO: Show an error message
                    return;
                }

                setSummaryOfProcessMessages(prevMessages => ({
                    ...prevMessages,
                    [agentExecutionResultId]: summaryOfProcessMessage
                }));
                setCurrentSummaryOfProcessMessage(summaryOfProcessMessage);
            });
    }, [summaryOfProcessMessages]);

    function getAssistantIcon(messageType) {
        let assistantIcon;
        if (messageType === AssistantRole) {
            assistantIcon =
                <Box className={styles.locusive_static_logo_container} style={{marginTop: "0.25rem"}}>
                    <img
                        src={LocusiveLogoUrl}
                        alt="Locusive logo"
                        style={{width: "16px", height: "16px"}}
                    />
                </Box>
        }
        else if (messageType === StatusMessageType) {
            assistantIcon = <Box className={styles.locusive_rotating_logo_container}>
                {/* TODO: GIF has additional space at the top - can throw multi-line alignment off */}
                <img src={LocusiveLoadingGifUrl} alt="Loading" style={{height: "24px", margin: "-3px 0 0 8px"}}/>
            </Box>
        }
        return assistantIcon;
    }

    function renderMessage(genericChatMessage) {
        const markdownContent = genericChatMessage.getMessage();
        const role = genericChatMessage.getRole()
        const isAssistant = role === AssistantRole
        const isStatusMessage = genericChatMessage?.id && genericChatMessage?.isStatusMessage
        const messageJustifyContent = isAssistant ? "flex-start" : "flex-end"
        const messageType = !isAssistant
            ? UserRole
            : (isAssistant && !isStatusMessage)
                ? AssistantRole
                : StatusMessageType;

        let assistantIcon = getAssistantIcon(messageType);

        return (
            <Box key={genericChatMessage.id} style={{display: "flex", justifyContent: messageJustifyContent}}>
                <Box>
                    {assistantIcon}
                </Box>
                <div>
                <div className={`${styles.locusive_message} ${styles[messageType]}`}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} components={MarkdownComponents}>
                        {markdownContent}
                    </ReactMarkdown>
                    {renderSources(genericChatMessage)}
                    {renderSeeDetailsButton(genericChatMessage)}
                </div>
                </div>
            </Box>
        )
    }

    function renderSources(genericChatMessage) {
        if (!genericChatMessage.sources) {
            return null;
        }

        return <div className={styles.locusiveSourceContainer}>
            {
                genericChatMessage.sources.map((source, index) => {
                    return renderSource(source, index);
                })
            }
        </div>
    }

    function renderSource(source, index) {
        return <div key={uuidv4()} className={styles.locusiveSource} onClick={source.getUrl ? () => window.open(source.getUrl(), "_blank") : null}>
            <img src={source.getImageurl()} alt={source.getName()} />
            <span>{source.getName()}</span>
        </div>
    }

    function renderSummaryOfProcess() {
        const markdownContent = currentSummaryOfProcessMessage.getSummary()
        const containsSqlQuery = markdownContent.includes("```sql")

        return (
            <Box className={styles.locusive_additional_details_container}>
                <Box className={styles.locusive_additional_details_header}>
                    {/*<div style={{position: "absolute", top: "1rem", right: "1rem"}}>*/}
                    {/*</div>*/}
                    <Box style={{display: "flex", flexFlow: "row nowrap", alignItems: "center"}}>
                        <InfoOutlinedIcon
                            className={styles.info_icon}
                            style={{color: "#D642B5", marginRight: "0.5rem"}}
                        />
                        <Typography variant="h3">Summary of Process</Typography>
                    </Box>
                    <IconButton size="small" onClick={() => setCurrentSummaryOfProcessMessage(null)}>
                        <CloseOutlinedIcon fontSize="small" style={{color: "#627D98"}} />
                    </IconButton>
                </Box>
                <Box style={{padding: "1rem 2rem"}}>
                    <ReactMarkdown
                        components={{
                            code(props) {
                                const {node, ...rest} = props
                                return (
                                    <SyntaxHighlighter
                                        {...rest}
                                        language={containsSqlQuery ? "sql" : "markdown"}
                                        // style={dracula}
                                        wrapLines={true}
                                        wrapLongLines={true}
                                    />
                                )
                            }
                        }}
                    >
                        {markdownContent}
                    </ReactMarkdown>
                </Box>
            </Box>
        )
    }

    function renderChatHeader() {
        return (
            <Box className={styles.locusive_chat_header}>
                <Box className={styles.locusive_flex_row} key={uuidv4()}>
                    <Box className={styles.locusive_static_logo_container} style={{marginRight: "0.5rem"}}>
                        <img
                            src={LocusiveLogoUrl}
                            alt="Locusive logo"
                            style={{width: "16px", height: "16px"}}
                        />
                    </Box>
                    <Typography variant="h3">
                        How can we help?
                    </Typography>
                </Box>
                <IconButton size="small" onClick={() => dispatchEvent(CloseChatMessageWindow)}>
                    <CloseOutlinedIcon fontSize="small" style={{color: "#627D98"}} />
                </IconButton>
            </Box>
        )
    }

    function renderWelcomeBanner() {
        if ((messages && messages.length > 0) || isBannerClosed) {
            return null;
        }

        return <div id={styles.welcomeBanner} className={isBannerClosed ? styles.locusiveHidden : ""}>
            <IconButton size="small" onClick={() => setIsBannerClosed(true)} id={styles.closeBannerButton}>
                <CloseOutlinedIcon fontSize="small" style={{color: "#8E8C99"}} />
            </IconButton>
            <div id={styles.bannerLogo}>
                <img src="https://storage.googleapis.com/locusive-resource-webapp/welcome-banner-logo-light.png" alt={"Welcome to Locusive"} />
            </div>
            <h3>Welcome to Locusive’s Assistant</h3>
            <span id={styles.bannerSubHeading}>Have a question about Locusive? We’ll do our best to help.</span>
        </div>
    }

    function renderContextualRecommendations() {
        if (messages && messages.length > 0) {
            return null;
        }

        return <div id={styles.contextualRecommendationsContainer}>
            {contextualRecommendations.map(contextualRecommendation => {
                return renderContextualRecommendation(contextualRecommendation)
            })}
        </div>
    }

    function handleClickOnContextualRecommendation(contextualRecommendation) {
        if (contextualRecommendation.getType() === ContextualRecommendationType.Link && contextualRecommendation.getNavigationlink()) {
            window.open(contextualRecommendation.getNavigationlink(), "_blank");
        }
        else {
            let userMessage = CreateGenericChatMessage(contextualRecommendation.getContent(), UserRole);
            let systemMessage = CreateGenericChatMessage(contextualRecommendation.getGeneratedresponse(), AssistantRole);
            setMessages([
                ...messages,
                userMessage,
                systemMessage
            ])
        }
        EventLogger.clickOnRecommendation(channelInstanceId, contextualRecommendation.getId(), currentUrl);
    }

    function renderContextualRecommendation(contextualRecommendation) {
        return <div className={styles.contextualRecommendationContainer} onClick={() => handleClickOnContextualRecommendation(contextualRecommendation)}>
            {
                contextualRecommendation.getType() === ContextualRecommendationType.Faq
                    ? <HelpOutlineOutlinedIcon style={{color: "#627D98"}} />
                    : <LinkIcon style={{color: "#627D98"}} />
            }
            {contextualRecommendation.getContent()}
        </div>
    }

    function renderMessagesBody() {
        return (
            <Box className={styles.locusive_messages} ref={chatRef}>
                {renderWelcomeBanner()}
                {renderContextualRecommendations()}
                {messages.map((message) => renderMessage(message))}
                {renderDetailsDrawer()}
            </Box>
        )
    }

    function renderDetailsDrawer() {
        return <Drawer
            anchor={"right"}
            open={currentSummaryOfProcessMessage !== null && currentSummaryOfProcessMessage !== undefined}
            onClose={() => setCurrentSummaryOfProcessMessage(null)}
            className={styles.locusive_additional_details_drawer}
        >
            {
                currentSummaryOfProcessMessage
                    ? renderSummaryOfProcess()
                    : null
            }
        </Drawer>
    }

    function renderChatFooter() {
        return (
            <Box className={styles.locusive_chat_footer}>
                <Typography variant="caption" color="textSecondary" p={1}>
                    <a href={"https://www.locusive.com"} target={"_blank"} id={styles.poweredByLocusiveLink} rel="noreferrer">Powered By Locusive</a>
                </Typography>
            </Box>
        )
    }

    return (
        <Box className={styles.locusive_embedded_chat_widget}>
            {renderChatHeader()}
            {renderMessagesBody()}
            <MessageForm
                handleSubmit={handleSubmitMessage}
                options={SlashCommandOptions}
                // optionsList={SlashCommandOptionsList}
                disableMessages={disableMessages}
            />
            {renderChatFooter()}
        </Box>
    );
}

export default EmbeddedAssistantChat;



