// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#locusive-message-container {
    padding: 20px;
}

#locusive-message-container #locusive-send-button {
    color: #D642B5 !important;
}

#locusive-message-container .MuiInputBase-root {
    border: 1px solid #BCBAC7;
    border-radius: 20px;
    min-height: 60px;
    color: #211F26;
}

/* Target the textarea inside the TextField */
#locusive-message-container .MuiInputBase-root textarea {
    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

/* For WebKit browsers (Chrome, Safari) */
#locusive-message-container .MuiInputBase-root textarea::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

#locusive-message-container .MuiInputBase-root textarea::-webkit-scrollbar-track {
    background: transparent;
}

#locusive-message-container .MuiInputBase-root textarea::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmbeddedAssistantMessageForm/EmbeddedAssistantMessageForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA,6CAA6C;AAC7C;IACI,gBAAgB;IAChB,qBAAqB;IACrB,+CAA+C;AACnD;;AAEA,yCAAyC;AACzC;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;AACtB","sourcesContent":["#locusive-message-container {\n    padding: 20px;\n}\n\n#locusive-message-container #locusive-send-button {\n    color: #D642B5 !important;\n}\n\n#locusive-message-container .MuiInputBase-root {\n    border: 1px solid #BCBAC7;\n    border-radius: 20px;\n    min-height: 60px;\n    color: #211F26;\n}\n\n/* Target the textarea inside the TextField */\n#locusive-message-container .MuiInputBase-root textarea {\n    /* For Firefox */\n    scrollbar-width: thin;\n    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;\n}\n\n/* For WebKit browsers (Chrome, Safari) */\n#locusive-message-container .MuiInputBase-root textarea::-webkit-scrollbar {\n    height: 6px;\n    width: 6px;\n}\n\n#locusive-message-container .MuiInputBase-root textarea::-webkit-scrollbar-track {\n    background: transparent;\n}\n\n#locusive-message-container .MuiInputBase-root textarea::-webkit-scrollbar-thumb {\n    background-color: rgba(0, 0, 0, 0.3);\n    border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
