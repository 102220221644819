// src/components/EmbeddedAssistantMessageForm.jsx

// react
import React, { useEffect, useRef, useState } from "react";
// third party
import { v4 as uuidv4 } from "uuid";
// mui
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import './EmbeddedAssistantMessageForm.css';

// constants
export const DefaultInput = { label: "" };
export const DefaultPlaceHolder = { label: "Message..." };
export const DefaultOptions = [{ label: "No options available" }];

function EmbeddedAssistantMessageForm({ handleSubmit, options = DefaultOptions, disableMessages }) {
    const [userMessage, setUserMessage] = useState(DefaultInput);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!disableMessages && inputRef.current) {
            inputRef.current.focus();
        }
    }, [disableMessages]);

    function handleTextFieldChange(event) {
        const value = event.target.value;
        if (value) {
            setUserMessage({
                ...userMessage,
                label: value
            });
        } else {
            setUserMessage(DefaultInput);
        }
    }

    function handleOnSubmit(event) {
        event.preventDefault();
        const message = userMessage?.label;
        if (message && message.trim().length !== 0 && message.length >= 1) {
            handleSubmit(message);
        }

        setUserMessage(DefaultInput);
    }

    // Handle key down events for Enter and Shift+Enter
    function handleKeyDown(event) {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevent adding a new line
            handleOnSubmit(event);  // Submit the form
        }
        // If Shift+Enter, allow the default behavior (adding a new line)
    }

    return (
        <Box
            component="form"
            display="flex"
            alignItems="center"
            id="locusive-message-container"
            p={1}
            onSubmit={handleOnSubmit}
        >
            <TextField
                id="locusive-message-input"
                value={userMessage.label}
                onChange={handleTextFieldChange}
                placeholder={DefaultPlaceHolder.label}
                multiline
                maxRows={5}
                variant="outlined"
                size="small"
                onKeyDown={handleKeyDown}
                inputRef={inputRef}
                disabled={disableMessages}
                fullWidth
                sx={{
                    "& fieldset": { border: "none" },
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                role="button"
                                aria-label="Send Message"
                                name="send"
                                id="locusive-send-button"
                                type="submit"
                                onClick={handleOnSubmit}
                                disabled={disableMessages}
                            >
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: { paddingRight: 10 }
                }}
            />
        </Box>
    );
}

export default EmbeddedAssistantMessageForm;
