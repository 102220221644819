import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const FlatButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 20,
  display: "inline block",
  width: "fit-content",
  margin: "1em auto",
  lineHeight: 1.5,
  fontWeight: 700,
  border: '1px solid #DBD8E0',
  borderRadius: 8,

  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(255,255,255,.05)',
  },

  '&:disabled': {
    opacity: 0.7
  }
});

export default FlatButton;