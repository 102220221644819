export const LoadingMessages = [
    "Please give me a second...",
    "Processing your request...",
    "Just a minute...",
    "Hang tight, I'm on it!",
    "Taking a look...",
    "Working on it...",
    "Rounding up the bits and bytes...",
    "One moment, please...",
    "Be right back...",
    "Be right with you...",
    "Just a sec...",
    "Thinking...",
    "One second...",
    "Working...",
    "Just a sec...",
    "Reticulating splines...",
    "Aligning covariance matrices...",
    "Building data trees...",
    "Calculating inverse probability matrices...",
    "Cohorting exemplars...",
    "Decomposing singular values..."
];

export const ContextualRecommendationType = {
    Faq: "faq",
    Link: "link"
}

// Note: these constants are redefined in locusive-web-assistant.js because we cannot import them in that file from this location
export const CloseChatMessageWindow = "closeChatMessageWindow";
export const ChannelInstanceIsValidMessage = "channelInstanceIsValid";