// source: src/generated/api_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.com.locusive.api_service.v1.AcceptInvitationRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AddDocumentToIndexRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AddIntegrationRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AddIntegrationResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AgentExecutionRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AgentExecutionResultMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AgentMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ApiKeyMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.CompleteAccountSetupRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ContextualRecommendationMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ContextualRecommendationRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ContextualRecommendationsResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.CreateOrganizationRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.CreateUserRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.CreateUserResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.GenericChatMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.GetIntegrationsRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.GetUserRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.HealthCheckRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.HealthCheckResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.IncludedDataSourceMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.IndexedDocumentListMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.IndexedDocumentMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.InviteTeamMembersRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ListDocumentsRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.LocusiveUser', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.OrganizationMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.OrganizationsMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.PlaybookMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ReferenceGuideMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.RemoveMemberRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.RemoveMemberResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ScheduleMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SchemaColumnMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SimpleIdRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SimpleIdsRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SimpleSuccessResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SlackOauthTokenResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SlackTeamMemberMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SourceMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.StatusUpdateParameterCollection', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.StepMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.SummaryOfProcessMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.TrainingInstructionMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.TriggerTypeMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.UpdateUserResponse', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.UploadAndIndexFileRequest', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.UsageMetricsMessage', null, global);
goog.exportSymbol('proto.com.locusive.api_service.v1.ValidDomainsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.HealthCheckRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.HealthCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.HealthCheckRequest.displayName = 'proto.com.locusive.api_service.v1.HealthCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.HealthCheckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.HealthCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.HealthCheckResponse.displayName = 'proto.com.locusive.api_service.v1.HealthCheckResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SimpleSuccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.displayName = 'proto.com.locusive.api_service.v1.SimpleSuccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SimpleIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SimpleIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SimpleIdRequest.displayName = 'proto.com.locusive.api_service.v1.SimpleIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.GetIntegrationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.GetIntegrationsRequest.displayName = 'proto.com.locusive.api_service.v1.GetIntegrationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.SimpleIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SimpleIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SimpleIdsRequest.displayName = 'proto.com.locusive.api_service.v1.SimpleIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AgentMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.AgentMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AgentMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AgentMessage.displayName = 'proto.com.locusive.api_service.v1.AgentMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ContextualRecommendationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ContextualRecommendationMessage.displayName = 'proto.com.locusive.api_service.v1.ContextualRecommendationMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ContextualRecommendationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ContextualRecommendationRequest.displayName = 'proto.com.locusive.api_service.v1.ContextualRecommendationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ContextualRecommendationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.displayName = 'proto.com.locusive.api_service.v1.ContextualRecommendationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.ValidDomainsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ValidDomainsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ValidDomainsResponse.displayName = 'proto.com.locusive.api_service.v1.ValidDomainsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.CreateUserRequest.displayName = 'proto.com.locusive.api_service.v1.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.CreateOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.CreateOrganizationRequest.displayName = 'proto.com.locusive.api_service.v1.CreateOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.OrganizationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.OrganizationMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.OrganizationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.OrganizationMessage.displayName = 'proto.com.locusive.api_service.v1.OrganizationMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.UsageMetricsMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.UsageMetricsMessage.displayName = 'proto.com.locusive.api_service.v1.UsageMetricsMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.PlaybookMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.PlaybookMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.PlaybookMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.PlaybookMessage.displayName = 'proto.com.locusive.api_service.v1.PlaybookMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ScheduleMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.ScheduleMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ScheduleMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ScheduleMessage.displayName = 'proto.com.locusive.api_service.v1.ScheduleMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.StepMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.StepMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.StepMessage.displayName = 'proto.com.locusive.api_service.v1.StepMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.OrganizationsMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.OrganizationsMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.OrganizationsMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.OrganizationsMessage.displayName = 'proto.com.locusive.api_service.v1.OrganizationsMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.CreateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.CreateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.CreateUserResponse.displayName = 'proto.com.locusive.api_service.v1.CreateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.UpdateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.UpdateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.UpdateUserResponse.displayName = 'proto.com.locusive.api_service.v1.UpdateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.GetUserRequest.displayName = 'proto.com.locusive.api_service.v1.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.CompleteAccountSetupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.displayName = 'proto.com.locusive.api_service.v1.CompleteAccountSetupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.LocusiveUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.LocusiveUser.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.LocusiveUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.LocusiveUser.displayName = 'proto.com.locusive.api_service.v1.LocusiveUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.InviteTeamMembersRequest.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.InviteTeamMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.InviteTeamMembersRequest.displayName = 'proto.com.locusive.api_service.v1.InviteTeamMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AcceptInvitationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AcceptInvitationRequest.displayName = 'proto.com.locusive.api_service.v1.AcceptInvitationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.RemoveMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.RemoveMemberRequest.displayName = 'proto.com.locusive.api_service.v1.RemoveMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.RemoveMemberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.RemoveMemberResponse.displayName = 'proto.com.locusive.api_service.v1.RemoveMemberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.displayName = 'proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.displayName = 'proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.IncludedDataSourceMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.IncludedDataSourceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.IncludedDataSourceMessage.displayName = 'proto.com.locusive.api_service.v1.IncludedDataSourceMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.displayName = 'proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.displayName = 'proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.displayName = 'proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SlackTeamMemberMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SlackTeamMemberMessage.displayName = 'proto.com.locusive.api_service.v1.SlackTeamMemberMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SlackOauthTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SlackOauthTokenResponse.displayName = 'proto.com.locusive.api_service.v1.SlackOauthTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AddIntegrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AddIntegrationRequest.displayName = 'proto.com.locusive.api_service.v1.AddIntegrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AddIntegrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AddIntegrationResponse.displayName = 'proto.com.locusive.api_service.v1.AddIntegrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.displayName = 'proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.displayName = 'proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.displayName = 'proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.IndexedDocumentMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.IndexedDocumentMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.IndexedDocumentMessage.displayName = 'proto.com.locusive.api_service.v1.IndexedDocumentMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AddDocumentToIndexRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.displayName = 'proto.com.locusive.api_service.v1.AddDocumentToIndexRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.displayName = 'proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.displayName = 'proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.displayName = 'proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.displayName = 'proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ListDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ListDocumentsRequest.displayName = 'proto.com.locusive.api_service.v1.ListDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.IndexedDocumentListMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.IndexedDocumentListMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.IndexedDocumentListMessage.displayName = 'proto.com.locusive.api_service.v1.IndexedDocumentListMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.UploadAndIndexFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.displayName = 'proto.com.locusive.api_service.v1.UploadAndIndexFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ApiKeyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ApiKeyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ApiKeyMessage.displayName = 'proto.com.locusive.api_service.v1.ApiKeyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SchemaColumnMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SchemaColumnMessage.displayName = 'proto.com.locusive.api_service.v1.SchemaColumnMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ReferenceGuideMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ReferenceGuideMessage.displayName = 'proto.com.locusive.api_service.v1.ReferenceGuideMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.displayName = 'proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.TrainingInstructionMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.TrainingInstructionMessage.displayName = 'proto.com.locusive.api_service.v1.TrainingInstructionMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SummaryOfProcessMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SummaryOfProcessMessage.displayName = 'proto.com.locusive.api_service.v1.SummaryOfProcessMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.SourceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.SourceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.SourceMessage.displayName = 'proto.com.locusive.api_service.v1.SourceMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.AgentExecutionRequest.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AgentExecutionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AgentExecutionRequest.displayName = 'proto.com.locusive.api_service.v1.AgentExecutionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.locusive.api_service.v1.AgentExecutionResultMessage.repeatedFields_, null);
};
goog.inherits(proto.com.locusive.api_service.v1.AgentExecutionResultMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.AgentExecutionResultMessage.displayName = 'proto.com.locusive.api_service.v1.AgentExecutionResultMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.GenericChatMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.GenericChatMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.GenericChatMessage.displayName = 'proto.com.locusive.api_service.v1.GenericChatMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.locusive.api_service.v1.StatusUpdateParameterCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.displayName = 'proto.com.locusive.api_service.v1.StatusUpdateParameterCollection';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.HealthCheckRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.HealthCheckRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    challenge: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.HealthCheckRequest}
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.HealthCheckRequest;
  return proto.com.locusive.api_service.v1.HealthCheckRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.HealthCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.HealthCheckRequest}
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChallenge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.HealthCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.HealthCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChallenge();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string challenge = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.prototype.getChallenge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.HealthCheckRequest} returns this
 */
proto.com.locusive.api_service.v1.HealthCheckRequest.prototype.setChallenge = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.HealthCheckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.HealthCheckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.HealthCheckResponse}
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.HealthCheckResponse;
  return proto.com.locusive.api_service.v1.HealthCheckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.HealthCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.HealthCheckResponse}
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.HealthCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.HealthCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.HealthCheckResponse} returns this
 */
proto.com.locusive.api_service.v1.HealthCheckResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SimpleSuccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SimpleSuccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SimpleSuccessResponse}
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SimpleSuccessResponse;
  return proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SimpleSuccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SimpleSuccessResponse}
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SimpleSuccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.SimpleSuccessResponse} returns this
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SimpleSuccessResponse} returns this
 */
proto.com.locusive.api_service.v1.SimpleSuccessResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SimpleIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apikey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SimpleIdRequest}
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SimpleIdRequest;
  return proto.com.locusive.api_service.v1.SimpleIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SimpleIdRequest}
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SimpleIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SimpleIdRequest} returns this
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string apiKey = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SimpleIdRequest} returns this
 */
proto.com.locusive.api_service.v1.SimpleIdRequest.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.GetIntegrationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.GetIntegrationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agent: (f = msg.getAgent()) && proto.com.locusive.api_service.v1.AgentMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.GetIntegrationsRequest}
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.GetIntegrationsRequest;
  return proto.com.locusive.api_service.v1.GetIntegrationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.GetIntegrationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.GetIntegrationsRequest}
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 2:
      var value = new proto.com.locusive.api_service.v1.AgentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AgentMessage.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.GetIntegrationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.GetIntegrationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.locusive.api_service.v1.AgentMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string organizationId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetIntegrationsRequest} returns this
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AgentMessage agent = 2;
 * @return {?proto.com.locusive.api_service.v1.AgentMessage}
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.getAgent = function() {
  return /** @type{?proto.com.locusive.api_service.v1.AgentMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.AgentMessage, 2));
};


/**
 * @param {?proto.com.locusive.api_service.v1.AgentMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.GetIntegrationsRequest} returns this
*/
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.setAgent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.GetIntegrationsRequest} returns this
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.clearAgent = function() {
  return this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.GetIntegrationsRequest.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SimpleIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SimpleIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SimpleIdsRequest}
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SimpleIdsRequest;
  return proto.com.locusive.api_service.v1.SimpleIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SimpleIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SimpleIdsRequest}
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SimpleIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SimpleIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.SimpleIdsRequest} returns this
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.SimpleIdsRequest} returns this
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.SimpleIdsRequest} returns this
 */
proto.com.locusive.api_service.v1.SimpleIdsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.AgentMessage.repeatedFields_ = [4,5,8,11,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AgentMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AgentMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AgentMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cannonmemberssendmessages: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    whitelistedtopicsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    blacklistedtopicsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    preferredllmcategorytype: jspb.Message.getFieldWithDefault(msg, 6, ""),
    shouldsendpagecontent: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    excludedtoolidsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    traininginstructionsList: jspb.Message.toObjectList(msg.getTraininginstructionsList(),
    proto.com.locusive.api_service.v1.TrainingInstructionMessage.toObject, includeInstance),
    hideonmaxchatrequests: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    alloweddomainsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    organizationid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isopenonrender: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AgentMessage}
 */
proto.com.locusive.api_service.v1.AgentMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AgentMessage;
  return proto.com.locusive.api_service.v1.AgentMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AgentMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AgentMessage}
 */
proto.com.locusive.api_service.v1.AgentMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCannonmemberssendmessages(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addWhitelistedtopics(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBlacklistedtopics(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredllmcategorytype(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldsendpagecontent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addExcludedtoolids(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = new proto.com.locusive.api_service.v1.TrainingInstructionMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.TrainingInstructionMessage.deserializeBinaryFromReader);
      msg.addTraininginstructions(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideonmaxchatrequests(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addAlloweddomains(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsopenonrender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AgentMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AgentMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AgentMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCannonmemberssendmessages();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getWhitelistedtopicsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBlacklistedtopicsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getPreferredllmcategorytype();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getShouldsendpagecontent();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getExcludedtoolidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTraininginstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.com.locusive.api_service.v1.TrainingInstructionMessage.serializeBinaryToWriter
    );
  }
  f = message.getHideonmaxchatrequests();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getAlloweddomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsopenonrender();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool canNonMembersSendMessages = 3;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getCannonmemberssendmessages = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setCannonmemberssendmessages = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string whitelistedTopics = 4;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getWhitelistedtopicsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setWhitelistedtopicsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.addWhitelistedtopics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.clearWhitelistedtopicsList = function() {
  return this.setWhitelistedtopicsList([]);
};


/**
 * repeated string blacklistedTopics = 5;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getBlacklistedtopicsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setBlacklistedtopicsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.addBlacklistedtopics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.clearBlacklistedtopicsList = function() {
  return this.setBlacklistedtopicsList([]);
};


/**
 * optional string preferredLlmCategoryType = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getPreferredllmcategorytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setPreferredllmcategorytype = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool shouldSendPageContent = 7;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getShouldsendpagecontent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setShouldsendpagecontent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated string excludedToolIds = 8;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getExcludedtoolidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setExcludedtoolidsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.addExcludedtoolids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.clearExcludedtoolidsList = function() {
  return this.setExcludedtoolidsList([]);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated TrainingInstructionMessage trainingInstructions = 11;
 * @return {!Array<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getTraininginstructionsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.TrainingInstructionMessage, 11));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
*/
proto.com.locusive.api_service.v1.AgentMessage.prototype.setTraininginstructionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.addTraininginstructions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.locusive.api_service.v1.TrainingInstructionMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.clearTraininginstructionsList = function() {
  return this.setTraininginstructionsList([]);
};


/**
 * optional bool hideOnMaxChatRequests = 12;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getHideonmaxchatrequests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setHideonmaxchatrequests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * repeated string allowedDomains = 13;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getAlloweddomainsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setAlloweddomainsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.addAlloweddomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.clearAlloweddomainsList = function() {
  return this.setAlloweddomainsList([]);
};


/**
 * optional string organizationId = 14;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool isOpenOnRender = 15;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.getIsopenonrender = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AgentMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentMessage.prototype.setIsopenonrender = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ContextualRecommendationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    generatedresponse: jspb.Message.getFieldWithDefault(msg, 6, ""),
    navigationlink: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ContextualRecommendationMessage;
  return proto.com.locusive.api_service.v1.ContextualRecommendationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeneratedresponse(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNavigationlink(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ContextualRecommendationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGeneratedresponse();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNavigationlink();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string agentId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string generatedResponse = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getGeneratedresponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setGeneratedresponse = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string navigationLink = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getNavigationlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setNavigationlink = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
*/
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
*/
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ContextualRecommendationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ContextualRecommendationRequest;
  return proto.com.locusive.api_service.v1.ContextualRecommendationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ContextualRecommendationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendationsList: jspb.Message.toObjectList(msg.getRecommendationsList(),
    proto.com.locusive.api_service.v1.ContextualRecommendationMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ContextualRecommendationsResponse;
  return proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.ContextualRecommendationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.ContextualRecommendationMessage.deserializeBinaryFromReader);
      msg.addRecommendations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.ContextualRecommendationMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContextualRecommendationMessage recommendations = 1;
 * @return {!Array<!proto.com.locusive.api_service.v1.ContextualRecommendationMessage>}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.prototype.getRecommendationsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.ContextualRecommendationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.ContextualRecommendationMessage, 1));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.ContextualRecommendationMessage>} value
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse} returns this
*/
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.prototype.setRecommendationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationMessage}
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.prototype.addRecommendations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.locusive.api_service.v1.ContextualRecommendationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse} returns this
 */
proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.prototype.clearRecommendationsList = function() {
  return this.setRecommendationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ValidDomainsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ValidDomainsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    domainsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ValidDomainsResponse}
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ValidDomainsResponse;
  return proto.com.locusive.api_service.v1.ValidDomainsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ValidDomainsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ValidDomainsResponse}
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ValidDomainsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ValidDomainsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string domains = 1;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.prototype.getDomainsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.ValidDomainsResponse} returns this
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.prototype.setDomainsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.ValidDomainsResponse} returns this
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.prototype.addDomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.ValidDomainsResponse} returns this
 */
proto.com.locusive.api_service.v1.ValidDomainsResponse.prototype.clearDomainsList = function() {
  return this.setDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userdataMap: (f = msg.getUserdataMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.CreateUserRequest}
 */
proto.com.locusive.api_service.v1.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.CreateUserRequest;
  return proto.com.locusive.api_service.v1.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.CreateUserRequest}
 */
proto.com.locusive.api_service.v1.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getUserdataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserdataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> userData = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.locusive.api_service.v1.CreateUserRequest.prototype.getUserdataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.com.locusive.api_service.v1.CreateUserRequest} returns this
 */
proto.com.locusive.api_service.v1.CreateUserRequest.prototype.clearUserdataMap = function() {
  this.getUserdataMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.CreateOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.CreateOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.CreateOrganizationRequest}
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.CreateOrganizationRequest;
  return proto.com.locusive.api_service.v1.CreateOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.CreateOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.CreateOrganizationRequest}
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.CreateOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.CreateOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.CreateOrganizationRequest} returns this
 */
proto.com.locusive.api_service.v1.CreateOrganizationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.OrganizationMessage.repeatedFields_ = [3,4,5,6,13,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.OrganizationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.OrganizationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.OrganizationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    authorizedslackworkspacesList: jspb.Message.toObjectList(msg.getAuthorizedslackworkspacesList(),
    proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.toObject, includeInstance),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.com.locusive.api_service.v1.LocusiveUser.toObject, includeInstance),
    apikeysList: jspb.Message.toObjectList(msg.getApikeysList(),
    proto.com.locusive.api_service.v1.ApiKeyMessage.toObject, includeInstance),
    playbooksList: jspb.Message.toObjectList(msg.getPlaybooksList(),
    proto.com.locusive.api_service.v1.PlaybookMessage.toObject, includeInstance),
    shouldusesearchengineinchats: (f = msg.getShouldusesearchengineinchats()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    shouldusellmclientinchats: (f = msg.getShouldusellmclientinchats()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    onboardingcompletedat: (f = msg.getOnboardingcompletedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    assistantemailaddress: jspb.Message.getFieldWithDefault(msg, 10, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 11, ""),
    companydescription: jspb.Message.getFieldWithDefault(msg, 12, ""),
    traininginstructionsList: jspb.Message.toObjectList(msg.getTraininginstructionsList(),
    proto.com.locusive.api_service.v1.TrainingInstructionMessage.toObject, includeInstance),
    monthlychatrequestsoverride: jspb.Message.getFieldWithDefault(msg, 14, 0),
    monthlyapirequestsoverride: jspb.Message.getFieldWithDefault(msg, 15, 0),
    subscriptionstartsat: (f = msg.getSubscriptionstartsat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    subscriptionendsat: (f = msg.getSubscriptionendsat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    totalchatrequestsoverride: jspb.Message.getFieldWithDefault(msg, 18, 0),
    agentsList: jspb.Message.toObjectList(msg.getAgentsList(),
    proto.com.locusive.api_service.v1.AgentMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.OrganizationMessage;
  return proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.OrganizationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.deserializeBinaryFromReader);
      msg.addAuthorizedslackworkspaces(value);
      break;
    case 4:
      var value = new proto.com.locusive.api_service.v1.LocusiveUser;
      reader.readMessage(value,proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    case 5:
      var value = new proto.com.locusive.api_service.v1.ApiKeyMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.ApiKeyMessage.deserializeBinaryFromReader);
      msg.addApikeys(value);
      break;
    case 6:
      var value = new proto.com.locusive.api_service.v1.PlaybookMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.PlaybookMessage.deserializeBinaryFromReader);
      msg.addPlaybooks(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setShouldusesearchengineinchats(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setShouldusellmclientinchats(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOnboardingcompletedat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssistantemailaddress(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanydescription(value);
      break;
    case 13:
      var value = new proto.com.locusive.api_service.v1.TrainingInstructionMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.TrainingInstructionMessage.deserializeBinaryFromReader);
      msg.addTraininginstructions(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlychatrequestsoverride(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyapirequestsoverride(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSubscriptionstartsat(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSubscriptionendsat(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalchatrequestsoverride(value);
      break;
    case 19:
      var value = new proto.com.locusive.api_service.v1.AgentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AgentMessage.deserializeBinaryFromReader);
      msg.addAgents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.OrganizationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.OrganizationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.OrganizationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuthorizedslackworkspacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.serializeBinaryToWriter
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.locusive.api_service.v1.LocusiveUser.serializeBinaryToWriter
    );
  }
  f = message.getApikeysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.locusive.api_service.v1.ApiKeyMessage.serializeBinaryToWriter
    );
  }
  f = message.getPlaybooksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.locusive.api_service.v1.PlaybookMessage.serializeBinaryToWriter
    );
  }
  f = message.getShouldusesearchengineinchats();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getShouldusellmclientinchats();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOnboardingcompletedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAssistantemailaddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCompanydescription();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTraininginstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.com.locusive.api_service.v1.TrainingInstructionMessage.serializeBinaryToWriter
    );
  }
  f = message.getMonthlychatrequestsoverride();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getMonthlyapirequestsoverride();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getSubscriptionstartsat();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionendsat();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTotalchatrequestsoverride();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getAgentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.com.locusive.api_service.v1.AgentMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AuthorizedSlackWorkspaceMessage authorizedSlackWorkspaces = 3;
 * @return {!Array<!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getAuthorizedslackworkspacesList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage, 3));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setAuthorizedslackworkspacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.addAuthorizedslackworkspaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearAuthorizedslackworkspacesList = function() {
  return this.setAuthorizedslackworkspacesList([]);
};


/**
 * repeated LocusiveUser members = 4;
 * @return {!Array<!proto.com.locusive.api_service.v1.LocusiveUser>}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.LocusiveUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.LocusiveUser, 4));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.LocusiveUser>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.LocusiveUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.locusive.api_service.v1.LocusiveUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * repeated ApiKeyMessage apiKeys = 5;
 * @return {!Array<!proto.com.locusive.api_service.v1.ApiKeyMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getApikeysList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.ApiKeyMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.ApiKeyMessage, 5));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.ApiKeyMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setApikeysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ApiKeyMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.addApikeys = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.locusive.api_service.v1.ApiKeyMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearApikeysList = function() {
  return this.setApikeysList([]);
};


/**
 * repeated PlaybookMessage playbooks = 6;
 * @return {!Array<!proto.com.locusive.api_service.v1.PlaybookMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getPlaybooksList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.PlaybookMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.PlaybookMessage, 6));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.PlaybookMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setPlaybooksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.PlaybookMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.addPlaybooks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.locusive.api_service.v1.PlaybookMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearPlaybooksList = function() {
  return this.setPlaybooksList([]);
};


/**
 * optional google.protobuf.BoolValue shouldUseSearchEngineInChats = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getShouldusesearchengineinchats = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setShouldusesearchengineinchats = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearShouldusesearchengineinchats = function() {
  return this.setShouldusesearchengineinchats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.hasShouldusesearchengineinchats = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue shouldUseLlmClientInChats = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getShouldusellmclientinchats = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setShouldusellmclientinchats = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearShouldusellmclientinchats = function() {
  return this.setShouldusellmclientinchats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.hasShouldusellmclientinchats = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp onboardingCompletedAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getOnboardingcompletedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setOnboardingcompletedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearOnboardingcompletedat = function() {
  return this.setOnboardingcompletedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.hasOnboardingcompletedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string assistantEmailAddress = 10;
 * @return {string}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getAssistantemailaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setAssistantemailaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string domain = 11;
 * @return {string}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string companyDescription = 12;
 * @return {string}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getCompanydescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setCompanydescription = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated TrainingInstructionMessage trainingInstructions = 13;
 * @return {!Array<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getTraininginstructionsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.TrainingInstructionMessage, 13));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setTraininginstructionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.addTraininginstructions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.com.locusive.api_service.v1.TrainingInstructionMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearTraininginstructionsList = function() {
  return this.setTraininginstructionsList([]);
};


/**
 * optional int64 monthlyChatRequestsOverride = 14;
 * @return {number}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getMonthlychatrequestsoverride = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setMonthlychatrequestsoverride = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 monthlyApiRequestsOverride = 15;
 * @return {number}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getMonthlyapirequestsoverride = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setMonthlyapirequestsoverride = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp subscriptionStartsAt = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getSubscriptionstartsat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setSubscriptionstartsat = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearSubscriptionstartsat = function() {
  return this.setSubscriptionstartsat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.hasSubscriptionstartsat = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp subscriptionEndsAt = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getSubscriptionendsat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setSubscriptionendsat = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearSubscriptionendsat = function() {
  return this.setSubscriptionendsat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.hasSubscriptionendsat = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int64 totalChatRequestsOverride = 18;
 * @return {number}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getTotalchatrequestsoverride = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setTotalchatrequestsoverride = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * repeated AgentMessage agents = 19;
 * @return {!Array<!proto.com.locusive.api_service.v1.AgentMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.getAgentsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.AgentMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.AgentMessage, 19));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.AgentMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.setAgentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AgentMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AgentMessage}
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.addAgents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.com.locusive.api_service.v1.AgentMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationMessage.prototype.clearAgentsList = function() {
  return this.setAgentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.UsageMetricsMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.UsageMetricsMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    monthlychatrequests: jspb.Message.getFieldWithDefault(msg, 1, 0),
    monthlyapirequests: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalchatrequests: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.UsageMetricsMessage}
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.UsageMetricsMessage;
  return proto.com.locusive.api_service.v1.UsageMetricsMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.UsageMetricsMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.UsageMetricsMessage}
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlychatrequests(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyapirequests(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalchatrequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.UsageMetricsMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.UsageMetricsMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonthlychatrequests();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMonthlyapirequests();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalchatrequests();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 monthlyChatRequests = 1;
 * @return {number}
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.getMonthlychatrequests = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.UsageMetricsMessage} returns this
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.setMonthlychatrequests = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 monthlyApiRequests = 2;
 * @return {number}
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.getMonthlyapirequests = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.UsageMetricsMessage} returns this
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.setMonthlyapirequests = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 totalChatRequests = 3;
 * @return {number}
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.getTotalchatrequests = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.UsageMetricsMessage} returns this
 */
proto.com.locusive.api_service.v1.UsageMetricsMessage.prototype.setTotalchatrequests = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.PlaybookMessage.repeatedFields_ = [5,6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.PlaybookMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.PlaybookMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.PlaybookMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    triggertype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    keywordsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    topicsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    responseinstructions: jspb.Message.getFieldWithDefault(msg, 7, ""),
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.com.locusive.api_service.v1.StepMessage.toObject, includeInstance),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    schedule: (f = msg.getSchedule()) && proto.com.locusive.api_service.v1.ScheduleMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.PlaybookMessage;
  return proto.com.locusive.api_service.v1.PlaybookMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.PlaybookMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.com.locusive.api_service.v1.TriggerTypeMessage} */ (reader.readEnum());
      msg.setTriggertype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeywords(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTopics(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseinstructions(value);
      break;
    case 8:
      var value = new proto.com.locusive.api_service.v1.StepMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.StepMessage.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 11:
      var value = new proto.com.locusive.api_service.v1.ScheduleMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.ScheduleMessage.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.PlaybookMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.PlaybookMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.PlaybookMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTriggertype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getResponseinstructions();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.com.locusive.api_service.v1.StepMessage.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.com.locusive.api_service.v1.ScheduleMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organizationId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional TriggerTypeMessage triggerType = 4;
 * @return {!proto.com.locusive.api_service.v1.TriggerTypeMessage}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getTriggertype = function() {
  return /** @type {!proto.com.locusive.api_service.v1.TriggerTypeMessage} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.locusive.api_service.v1.TriggerTypeMessage} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setTriggertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string keywords = 5;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setKeywordsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.addKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};


/**
 * repeated string topics = 6;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getTopicsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setTopicsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.addTopics = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};


/**
 * optional string responseInstructions = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getResponseinstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setResponseinstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated StepMessage steps = 8;
 * @return {!Array<!proto.com.locusive.api_service.v1.StepMessage>}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.StepMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.StepMessage, 8));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.StepMessage>} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
*/
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.StepMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.StepMessage}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.locusive.api_service.v1.StepMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};


/**
 * optional google.protobuf.Timestamp createdAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
*/
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
*/
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ScheduleMessage schedule = 11;
 * @return {?proto.com.locusive.api_service.v1.ScheduleMessage}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.getSchedule = function() {
  return /** @type{?proto.com.locusive.api_service.v1.ScheduleMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.ScheduleMessage, 11));
};


/**
 * @param {?proto.com.locusive.api_service.v1.ScheduleMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
*/
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.PlaybookMessage} returns this
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.PlaybookMessage.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.ScheduleMessage.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ScheduleMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ScheduleMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ScheduleMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: jspb.Message.getFieldWithDefault(msg, 1, ""),
    daysofweekList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    dayofmonth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeofday: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    timezone: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gcpjobid: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ScheduleMessage;
  return proto.com.locusive.api_service.v1.ScheduleMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ScheduleMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDaysofweek(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayofmonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeofday(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGcpjobid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ScheduleMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ScheduleMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ScheduleMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDaysofweekList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDayofmonth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimeofday();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGcpjobid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string frequency = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string daysOfWeek = 2;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getDaysofweekList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setDaysofweekList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.addDaysofweek = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.clearDaysofweekList = function() {
  return this.setDaysofweekList([]);
};


/**
 * optional int32 dayOfMonth = 3;
 * @return {number}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getDayofmonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setDayofmonth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string timeOfDay = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getTimeofday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setTimeofday = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp startDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
*/
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp endDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
*/
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string timezone = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string gcpJobId = 8;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.getGcpjobid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ScheduleMessage} returns this
 */
proto.com.locusive.api_service.v1.ScheduleMessage.prototype.setGcpjobid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.StepMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.StepMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.StepMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    tool: jspb.Message.getFieldWithDefault(msg, 1, ""),
    input: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outputvariablename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inputvariablename: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outputvariabledescription: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.StepMessage}
 */
proto.com.locusive.api_service.v1.StepMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.StepMessage;
  return proto.com.locusive.api_service.v1.StepMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.StepMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.StepMessage}
 */
proto.com.locusive.api_service.v1.StepMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTool(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputvariablename(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputvariablename(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutputvariabledescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.StepMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.StepMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.StepMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTool();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInput();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutputvariablename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInputvariablename();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutputvariabledescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string tool = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.getTool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StepMessage} returns this
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.setTool = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string input = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.getInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StepMessage} returns this
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.setInput = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StepMessage} returns this
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string outputVariableName = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.getOutputvariablename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StepMessage} returns this
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.setOutputvariablename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string inputVariableName = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.getInputvariablename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StepMessage} returns this
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.setInputvariablename = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string outputVariableDescription = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.getOutputvariabledescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StepMessage} returns this
 */
proto.com.locusive.api_service.v1.StepMessage.prototype.setOutputvariabledescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.OrganizationsMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.OrganizationsMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.com.locusive.api_service.v1.OrganizationMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.OrganizationsMessage}
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.OrganizationsMessage;
  return proto.com.locusive.api_service.v1.OrganizationsMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.OrganizationsMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.OrganizationsMessage}
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.OrganizationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.OrganizationsMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.OrganizationsMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.OrganizationMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrganizationMessage organizations = 1;
 * @return {!Array<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.OrganizationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.OrganizationMessage, 1));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.OrganizationMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationsMessage} returns this
*/
proto.com.locusive.api_service.v1.OrganizationsMessage.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.OrganizationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage}
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.locusive.api_service.v1.OrganizationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationsMessage} returns this
 */
proto.com.locusive.api_service.v1.OrganizationsMessage.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.CreateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.CreateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.CreateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CreateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.CreateUserResponse}
 */
proto.com.locusive.api_service.v1.CreateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.CreateUserResponse;
  return proto.com.locusive.api_service.v1.CreateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.CreateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.CreateUserResponse}
 */
proto.com.locusive.api_service.v1.CreateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.CreateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.CreateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.CreateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CreateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.CreateUserResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.CreateUserResponse} returns this
 */
proto.com.locusive.api_service.v1.CreateUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.CreateUserResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.CreateUserResponse} returns this
 */
proto.com.locusive.api_service.v1.CreateUserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.UpdateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.UpdateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    user: (f = msg.getUser()) && proto.com.locusive.api_service.v1.LocusiveUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.UpdateUserResponse}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.UpdateUserResponse;
  return proto.com.locusive.api_service.v1.UpdateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.UpdateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.UpdateUserResponse}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.com.locusive.api_service.v1.LocusiveUser;
      reader.readMessage(value,proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.UpdateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.UpdateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.locusive.api_service.v1.LocusiveUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.UpdateUserResponse} returns this
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UpdateUserResponse} returns this
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LocusiveUser user = 3;
 * @return {?proto.com.locusive.api_service.v1.LocusiveUser}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.getUser = function() {
  return /** @type{?proto.com.locusive.api_service.v1.LocusiveUser} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.LocusiveUser, 3));
};


/**
 * @param {?proto.com.locusive.api_service.v1.LocusiveUser|undefined} value
 * @return {!proto.com.locusive.api_service.v1.UpdateUserResponse} returns this
*/
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.UpdateUserResponse} returns this
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.UpdateUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auth0id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    slackteammemberid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.GetUserRequest}
 */
proto.com.locusive.api_service.v1.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.GetUserRequest;
  return proto.com.locusive.api_service.v1.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.GetUserRequest}
 */
proto.com.locusive.api_service.v1.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuth0id(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackteammemberid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuth0id();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSlackteammemberid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetUserRequest} returns this
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetUserRequest} returns this
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string auth0Id = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.getAuth0id = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetUserRequest} returns this
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.setAuth0id = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string slackTeamMemberId = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.getSlackteammemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetUserRequest} returns this
 */
proto.com.locusive.api_service.v1.GetUserRequest.prototype.setSlackteammemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.com.locusive.api_service.v1.LocusiveUser.toObject(includeInstance, f),
    organizationname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    wasinvitedtoorganization: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.CompleteAccountSetupRequest;
  return proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.LocusiveUser;
      reader.readMessage(value,proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWasinvitedtoorganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.LocusiveUser.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWasinvitedtoorganization();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional LocusiveUser user = 1;
 * @return {?proto.com.locusive.api_service.v1.LocusiveUser}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.getUser = function() {
  return /** @type{?proto.com.locusive.api_service.v1.LocusiveUser} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.LocusiveUser, 1));
};


/**
 * @param {?proto.com.locusive.api_service.v1.LocusiveUser|undefined} value
 * @return {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} returns this
*/
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} returns this
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string organizationName = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.getOrganizationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} returns this
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.setOrganizationname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool wasInvitedToOrganization = 3;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.getWasinvitedtoorganization = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} returns this
 */
proto.com.locusive.api_service.v1.CompleteAccountSetupRequest.prototype.setWasinvitedtoorganization = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.LocusiveUser.repeatedFields_ = [10,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.LocusiveUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.LocusiveUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.LocusiveUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emailverified: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    auth0id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    picture: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phoneverified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    authorizedintegrationsList: jspb.Message.toObjectList(msg.getAuthorizedintegrationsList(),
    proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.toObject, includeInstance),
    accountsetupcompletedat: (f = msg.getAccountsetupcompletedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    heardaboutussource: jspb.Message.getFieldWithDefault(msg, 14, ""),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.com.locusive.api_service.v1.OrganizationMessage.toObject, includeInstance),
    role: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser}
 */
proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.LocusiveUser;
  return proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.LocusiveUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser}
 */
proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailverified(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuth0id(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicture(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoneverified(value);
      break;
    case 10:
      var value = new proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinaryFromReader);
      msg.addAuthorizedintegrations(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAccountsetupcompletedat(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeardaboutussource(value);
      break;
    case 15:
      var value = new proto.com.locusive.api_service.v1.OrganizationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.LocusiveUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.LocusiveUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.LocusiveUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmailverified();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAuth0id();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPicture();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhoneverified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAuthorizedintegrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.serializeBinaryToWriter
    );
  }
  f = message.getAccountsetupcompletedat();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHeardaboutussource();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.com.locusive.api_service.v1.OrganizationMessage.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string firstName = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastName = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool emailVerified = 5;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getEmailverified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setEmailverified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string auth0Id = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getAuth0id = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setAuth0id = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string picture = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool phoneVerified = 9;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getPhoneverified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setPhoneverified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * repeated AuthorizedIntegrationMessage authorizedIntegrations = 10;
 * @return {!Array<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getAuthorizedintegrationsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage, 10));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
*/
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setAuthorizedintegrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.addAuthorizedintegrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.clearAuthorizedintegrationsList = function() {
  return this.setAuthorizedintegrationsList([]);
};


/**
 * optional google.protobuf.Timestamp accountSetupCompletedAt = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getAccountsetupcompletedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
*/
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setAccountsetupcompletedat = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.clearAccountsetupcompletedat = function() {
  return this.setAccountsetupcompletedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.hasAccountsetupcompletedat = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
*/
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
*/
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string heardAboutUsSource = 14;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getHeardaboutussource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setHeardaboutussource = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated OrganizationMessage organizations = 15;
 * @return {!Array<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.OrganizationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.OrganizationMessage, 15));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.OrganizationMessage>} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
*/
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.OrganizationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.OrganizationMessage}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.com.locusive.api_service.v1.OrganizationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};


/**
 * optional string role = 16;
 * @return {string}
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.LocusiveUser} returns this
 */
proto.com.locusive.api_service.v1.LocusiveUser.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.InviteTeamMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest}
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.InviteTeamMembersRequest;
  return proto.com.locusive.api_service.v1.InviteTeamMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest}
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.InviteTeamMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string organizationId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} returns this
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string emails = 2;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.getEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} returns this
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.setEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} returns this
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.addEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} returns this
 */
proto.com.locusive.api_service.v1.InviteTeamMembersRequest.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AcceptInvitationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AcceptInvitationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationmemberid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AcceptInvitationRequest}
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AcceptInvitationRequest;
  return proto.com.locusive.api_service.v1.AcceptInvitationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AcceptInvitationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AcceptInvitationRequest}
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationmemberid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AcceptInvitationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AcceptInvitationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationmemberid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string organizationMemberId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.prototype.getOrganizationmemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AcceptInvitationRequest} returns this
 */
proto.com.locusive.api_service.v1.AcceptInvitationRequest.prototype.setOrganizationmemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.RemoveMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberRequest}
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.RemoveMemberRequest;
  return proto.com.locusive.api_service.v1.RemoveMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberRequest}
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.RemoveMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organizationId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RemoveMemberRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.RemoveMemberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && proto.com.locusive.api_service.v1.OrganizationMessage.toObject(includeInstance, f),
    wasorganizationdeleted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberResponse}
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.RemoveMemberResponse;
  return proto.com.locusive.api_service.v1.RemoveMemberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberResponse}
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.OrganizationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWasorganizationdeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.RemoveMemberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.OrganizationMessage.serializeBinaryToWriter
    );
  }
  f = message.getWasorganizationdeleted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional OrganizationMessage organization = 1;
 * @return {?proto.com.locusive.api_service.v1.OrganizationMessage}
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.getOrganization = function() {
  return /** @type{?proto.com.locusive.api_service.v1.OrganizationMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.OrganizationMessage, 1));
};


/**
 * @param {?proto.com.locusive.api_service.v1.OrganizationMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberResponse} returns this
*/
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberResponse} returns this
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool wasOrganizationDeleted = 2;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.getWasorganizationdeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.RemoveMemberResponse} returns this
 */
proto.com.locusive.api_service.v1.RemoveMemberResponse.prototype.setWasorganizationdeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest}
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest;
  return proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest}
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fieldName = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.prototype.getFieldname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIncludedDataSourcesRequest.prototype.setFieldname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.repeatedFields_ = [11,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oauthaccesstoken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oauthrefreshtoken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopes: jspb.Message.getFieldWithDefault(msg, 6, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    issuedat: (f = msg.getIssuedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    includeddatasourcesList: jspb.Message.toObjectList(msg.getIncludeddatasourcesList(),
    proto.com.locusive.api_service.v1.IncludedDataSourceMessage.toObject, includeInstance),
    organization: (f = msg.getOrganization()) && proto.com.locusive.api_service.v1.OrganizationMessage.toObject(includeInstance, f),
    databasetype: jspb.Message.getFieldWithDefault(msg, 13, ""),
    encryptedconnectionstring: jspb.Message.getFieldWithDefault(msg, 14, ""),
    friendlyname: jspb.Message.getFieldWithDefault(msg, 15, ""),
    referenceguide: (f = msg.getReferenceguide()) && proto.com.locusive.api_service.v1.ReferenceGuideMessage.toObject(includeInstance, f),
    schemalastrefreshedat: (f = msg.getSchemalastrefreshedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    refreshstatus: jspb.Message.getFieldWithDefault(msg, 18, ""),
    exampledatabasequeriesList: jspb.Message.toObjectList(msg.getExampledatabasequeriesList(),
    proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.toObject, includeInstance),
    installerjwtsub: jspb.Message.getFieldWithDefault(msg, 20, ""),
    installerjwtemail: jspb.Message.getFieldWithDefault(msg, 21, ""),
    nativeintegrationid: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage;
  return proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOauthaccesstoken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOauthrefreshtoken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopes(value);
      break;
    case 7:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssuedat(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 11:
      var value = new proto.com.locusive.api_service.v1.IncludedDataSourceMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.IncludedDataSourceMessage.deserializeBinaryFromReader);
      msg.addIncludeddatasources(value);
      break;
    case 12:
      var value = new proto.com.locusive.api_service.v1.OrganizationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabasetype(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncryptedconnectionstring(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFriendlyname(value);
      break;
    case 16:
      var value = new proto.com.locusive.api_service.v1.ReferenceGuideMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.ReferenceGuideMessage.deserializeBinaryFromReader);
      msg.setReferenceguide(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSchemalastrefreshedat(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshstatus(value);
      break;
    case 19:
      var value = new proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.deserializeBinaryFromReader);
      msg.addExampledatabasequeries(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstallerjwtsub(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstallerjwtemail(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setNativeintegrationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOauthaccesstoken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOauthrefreshtoken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopes();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getIssuedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIncludeddatasourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.com.locusive.api_service.v1.IncludedDataSourceMessage.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.com.locusive.api_service.v1.OrganizationMessage.serializeBinaryToWriter
    );
  }
  f = message.getDatabasetype();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getEncryptedconnectionstring();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFriendlyname();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getReferenceguide();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.com.locusive.api_service.v1.ReferenceGuideMessage.serializeBinaryToWriter
    );
  }
  f = message.getSchemalastrefreshedat();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRefreshstatus();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getExampledatabasequeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.serializeBinaryToWriter
    );
  }
  f = message.getInstallerjwtsub();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getInstallerjwtemail();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getNativeintegrationid();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userId = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string oauthAccessToken = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getOauthaccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setOauthaccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string oauthRefreshToken = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getOauthrefreshtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setOauthrefreshtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scopes = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getScopes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setScopes = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * map<string, string> metadata = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;
};


/**
 * optional google.protobuf.Timestamp issuedAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getIssuedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setIssuedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearIssuedat = function() {
  return this.setIssuedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.hasIssuedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated IncludedDataSourceMessage includedDataSources = 11;
 * @return {!Array<!proto.com.locusive.api_service.v1.IncludedDataSourceMessage>}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getIncludeddatasourcesList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.IncludedDataSourceMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.IncludedDataSourceMessage, 11));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.IncludedDataSourceMessage>} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setIncludeddatasourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.addIncludeddatasources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.locusive.api_service.v1.IncludedDataSourceMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearIncludeddatasourcesList = function() {
  return this.setIncludeddatasourcesList([]);
};


/**
 * optional OrganizationMessage organization = 12;
 * @return {?proto.com.locusive.api_service.v1.OrganizationMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getOrganization = function() {
  return /** @type{?proto.com.locusive.api_service.v1.OrganizationMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.OrganizationMessage, 12));
};


/**
 * @param {?proto.com.locusive.api_service.v1.OrganizationMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string databaseType = 13;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getDatabasetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setDatabasetype = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string encryptedConnectionString = 14;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getEncryptedconnectionstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setEncryptedconnectionstring = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string friendlyName = 15;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getFriendlyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setFriendlyname = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional ReferenceGuideMessage referenceGuide = 16;
 * @return {?proto.com.locusive.api_service.v1.ReferenceGuideMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getReferenceguide = function() {
  return /** @type{?proto.com.locusive.api_service.v1.ReferenceGuideMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.ReferenceGuideMessage, 16));
};


/**
 * @param {?proto.com.locusive.api_service.v1.ReferenceGuideMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setReferenceguide = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearReferenceguide = function() {
  return this.setReferenceguide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.hasReferenceguide = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp schemaLastRefreshedAt = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getSchemalastrefreshedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setSchemalastrefreshedat = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearSchemalastrefreshedat = function() {
  return this.setSchemalastrefreshedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.hasSchemalastrefreshedat = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string refreshStatus = 18;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getRefreshstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setRefreshstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated ExampleDatabaseQueryMessage exampleDatabaseQueries = 19;
 * @return {!Array<!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getExampledatabasequeriesList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage, 19));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
*/
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setExampledatabasequeriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.addExampledatabasequeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.clearExampledatabasequeriesList = function() {
  return this.setExampledatabasequeriesList([]);
};


/**
 * optional string installerJwtSub = 20;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getInstallerjwtsub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setInstallerjwtsub = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string installerJwtEmail = 21;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getInstallerjwtemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setInstallerjwtemail = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string nativeIntegrationId = 22;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.getNativeintegrationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.prototype.setNativeintegrationid = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.IncludedDataSourceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nativeid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    authorizedintegrationid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    metadataMap: (f = msg.getMetadataMap()) ? f.toObject(includeInstance, undefined) : [],
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    indexeddocumentsList: jspb.Message.toObjectList(msg.getIndexeddocumentsList(),
    proto.com.locusive.api_service.v1.IndexedDocumentMessage.toObject, includeInstance),
    schemacolumnsList: jspb.Message.toObjectList(msg.getSchemacolumnsList(),
    proto.com.locusive.api_service.v1.SchemaColumnMessage.toObject, includeInstance),
    isincluded: (f = msg.getIsincluded()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.IncludedDataSourceMessage;
  return proto.com.locusive.api_service.v1.IncludedDataSourceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNativeid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizedintegrationid(value);
      break;
    case 8:
      var value = msg.getMetadataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 11:
      var value = new proto.com.locusive.api_service.v1.IndexedDocumentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinaryFromReader);
      msg.addIndexeddocuments(value);
      break;
    case 12:
      var value = new proto.com.locusive.api_service.v1.SchemaColumnMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.SchemaColumnMessage.deserializeBinaryFromReader);
      msg.addSchemacolumns(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsincluded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.IncludedDataSourceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNativeid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAuthorizedintegrationid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMetadataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIndexeddocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.com.locusive.api_service.v1.IndexedDocumentMessage.serializeBinaryToWriter
    );
  }
  f = message.getSchemacolumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.com.locusive.api_service.v1.SchemaColumnMessage.serializeBinaryToWriter
    );
  }
  f = message.getIsincluded();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string nativeId = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getNativeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setNativeid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string authorizedIntegrationId = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getAuthorizedintegrationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setAuthorizedintegrationid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * map<string, string> metadata = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getMetadataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.clearMetadataMap = function() {
  this.getMetadataMap().clear();
  return this;
};


/**
 * optional google.protobuf.Timestamp createdAt = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
*/
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
*/
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated IndexedDocumentMessage indexedDocuments = 11;
 * @return {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getIndexeddocumentsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.IndexedDocumentMessage, 11));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
*/
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setIndexeddocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.addIndexeddocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.com.locusive.api_service.v1.IndexedDocumentMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.clearIndexeddocumentsList = function() {
  return this.setIndexeddocumentsList([]);
};


/**
 * repeated SchemaColumnMessage schemaColumns = 12;
 * @return {!Array<!proto.com.locusive.api_service.v1.SchemaColumnMessage>}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getSchemacolumnsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.SchemaColumnMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.SchemaColumnMessage, 12));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.SchemaColumnMessage>} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
*/
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setSchemacolumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SchemaColumnMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.addSchemacolumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.com.locusive.api_service.v1.SchemaColumnMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.clearSchemacolumnsList = function() {
  return this.setSchemacolumnsList([]);
};


/**
 * optional google.protobuf.BoolValue isIncluded = 13;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.getIsincluded = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 13));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
*/
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.setIsincluded = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} returns this
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.clearIsincluded = function() {
  return this.setIsincluded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IncludedDataSourceMessage.prototype.hasIsincluded = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    teamname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage;
  return proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage}
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTeamname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string teamName = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.getTeamname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.setTeamname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string teamId = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.getTeamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage} returns this
 */
proto.com.locusive.api_service.v1.AuthorizedSlackWorkspaceMessage.prototype.setTeamid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slackuserid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slackteamid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest}
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest;
  return proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest}
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackuserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackteamid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlackuserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlackteamid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string slackUserId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.getSlackuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} returns this
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.setSlackuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slackTeamId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.getSlackteamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} returns this
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.setSlackteamid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} returns this
 */
proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slackcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slackteamid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slackteammemberid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    slackuserid: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest;
  return proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackcode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackteamid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackteammemberid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackuserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlackcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlackteamid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlackteammemberid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSlackuserid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string slackCode = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.getSlackcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.setSlackcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slackTeamId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.getSlackteamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.setSlackteamid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string slackTeamMemberId = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.getSlackteammemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.setSlackteammemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string firstName = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastName = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string slackUserId = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.getSlackuserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} returns this
 */
proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest.prototype.setSlackuserid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SlackTeamMemberMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    useroauthtoken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    registrationfailedat: (f = msg.getRegistrationfailedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tokenrevokedat: (f = msg.getTokenrevokedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    authorizedslackworkspaceid: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SlackTeamMemberMessage;
  return proto.com.locusive.api_service.v1.SlackTeamMemberMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseroauthtoken(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRegistrationfailedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTokenrevokedat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizedslackworkspaceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SlackTeamMemberMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUseroauthtoken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegistrationfailedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTokenrevokedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedslackworkspaceid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userOauthToken = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.getUseroauthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.setUseroauthtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp registrationFailedAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.getRegistrationfailedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
*/
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.setRegistrationfailedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.clearRegistrationfailedat = function() {
  return this.setRegistrationfailedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.hasRegistrationfailedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp tokenRevokedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.getTokenrevokedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
*/
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.setTokenrevokedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.clearTokenrevokedat = function() {
  return this.setTokenrevokedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.hasTokenrevokedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string authorizedSlackWorkspaceId = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.getAuthorizedslackworkspaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} returns this
 */
proto.com.locusive.api_service.v1.SlackTeamMemberMessage.prototype.setAuthorizedslackworkspaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SlackOauthTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SlackOauthTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oauthtoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SlackOauthTokenResponse}
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SlackOauthTokenResponse;
  return proto.com.locusive.api_service.v1.SlackOauthTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SlackOauthTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SlackOauthTokenResponse}
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOauthtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SlackOauthTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SlackOauthTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOauthtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string oauthToken = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.prototype.getOauthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SlackOauthTokenResponse} returns this
 */
proto.com.locusive.api_service.v1.SlackOauthTokenResponse.prototype.setOauthtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AddIntegrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    originalredirecturi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    integrationname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oauthaccesscode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    databaseconnectionstring: jspb.Message.getFieldWithDefault(msg, 5, ""),
    friendlyname: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AddIntegrationRequest;
  return proto.com.locusive.api_service.v1.AddIntegrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalredirecturi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOauthaccesscode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabaseconnectionstring(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFriendlyname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AddIntegrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOriginalredirecturi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntegrationname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOauthaccesscode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDatabaseconnectionstring();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFriendlyname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string organizationId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string originalRedirectUri = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.getOriginalredirecturi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.setOriginalredirecturi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string integrationName = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.getIntegrationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.setIntegrationname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string oauthAccessCode = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.getOauthaccesscode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.setOauthaccesscode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string databaseConnectionString = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.getDatabaseconnectionstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.setDatabaseconnectionstring = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string friendlyName = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.getFriendlyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationRequest.prototype.setFriendlyname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AddIntegrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    user: (f = msg.getUser()) && proto.com.locusive.api_service.v1.LocusiveUser.toObject(includeInstance, f),
    integrationid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isnewintegration: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AddIntegrationResponse;
  return proto.com.locusive.api_service.v1.AddIntegrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.com.locusive.api_service.v1.LocusiveUser;
      reader.readMessage(value,proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnewintegration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AddIntegrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.locusive.api_service.v1.LocusiveUser.serializeBinaryToWriter
    );
  }
  f = message.getIntegrationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsnewintegration();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LocusiveUser user = 3;
 * @return {?proto.com.locusive.api_service.v1.LocusiveUser}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.getUser = function() {
  return /** @type{?proto.com.locusive.api_service.v1.LocusiveUser} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.LocusiveUser, 3));
};


/**
 * @param {?proto.com.locusive.api_service.v1.LocusiveUser|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse} returns this
*/
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string integrationId = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.getIntegrationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.setIntegrationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isNewIntegration = 5;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.getIsnewintegration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AddIntegrationResponse} returns this
 */
proto.com.locusive.api_service.v1.AddIntegrationResponse.prototype.setIsnewintegration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorizedintegrationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newdatasourcesList: jspb.Message.toObjectList(msg.getNewdatasourcesList(),
    proto.com.locusive.api_service.v1.IncludedDataSourceMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest}
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest;
  return proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest}
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizedintegrationid(value);
      break;
    case 2:
      var value = new proto.com.locusive.api_service.v1.IncludedDataSourceMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.IncludedDataSourceMessage.deserializeBinaryFromReader);
      msg.addNewdatasources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorizedintegrationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewdatasourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.locusive.api_service.v1.IncludedDataSourceMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string authorizedIntegrationId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.getAuthorizedintegrationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.setAuthorizedintegrationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated IncludedDataSourceMessage newDataSources = 2;
 * @return {!Array<!proto.com.locusive.api_service.v1.IncludedDataSourceMessage>}
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.getNewdatasourcesList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.IncludedDataSourceMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.IncludedDataSourceMessage, 2));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.IncludedDataSourceMessage>} value
 * @return {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest} returns this
*/
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.setNewdatasourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage}
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.addNewdatasources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.locusive.api_service.v1.IncludedDataSourceMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest} returns this
 */
proto.com.locusive.api_service.v1.AddIncludedDataSourcesRequest.prototype.clearNewdatasourcesList = function() {
  return this.setNewdatasourcesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationsList: jspb.Message.toObjectList(msg.getIntegrationsList(),
    proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.toObject, includeInstance),
    indexeddocumentswithoutintegrationsList: jspb.Message.toObjectList(msg.getIndexeddocumentswithoutintegrationsList(),
    proto.com.locusive.api_service.v1.IndexedDocumentMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse;
  return proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinaryFromReader);
      msg.addIntegrations(value);
      break;
    case 2:
      var value = new proto.com.locusive.api_service.v1.IndexedDocumentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinaryFromReader);
      msg.addIndexeddocumentswithoutintegrations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.serializeBinaryToWriter
    );
  }
  f = message.getIndexeddocumentswithoutintegrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.locusive.api_service.v1.IndexedDocumentMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuthorizedIntegrationMessage integrations = 1;
 * @return {!Array<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.getIntegrationsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage, 1));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse} returns this
*/
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.setIntegrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.addIntegrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse} returns this
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.clearIntegrationsList = function() {
  return this.setIntegrationsList([]);
};


/**
 * repeated IndexedDocumentMessage indexedDocumentsWithoutIntegrations = 2;
 * @return {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.getIndexeddocumentswithoutintegrationsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.IndexedDocumentMessage, 2));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} value
 * @return {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse} returns this
*/
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.setIndexeddocumentswithoutintegrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage}
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.addIndexeddocumentswithoutintegrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.locusive.api_service.v1.IndexedDocumentMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse} returns this
 */
proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.prototype.clearIndexeddocumentswithoutintegrationsList = function() {
  return this.setIndexeddocumentswithoutintegrationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    integrationtype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest}
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest;
  return proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest}
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntegrationtype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string integrationType = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.prototype.getIntegrationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest} returns this
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.prototype.setIntegrationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organizationId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest} returns this
 */
proto.com.locusive.api_service.v1.GetIncludedDataSourcesForIntegrationTypeRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.IndexedDocumentMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    text: jspb.Message.getFieldWithDefault(msg, 5, ""),
    includeddatasourceid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    containingdocumentdescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    chunkidsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    organization: (f = msg.getOrganization()) && proto.com.locusive.api_service.v1.OrganizationMessage.toObject(includeInstance, f),
    tokensused: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 11, ""),
    percentagecompleted: (f = msg.getPercentagecompleted()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    lastindexedat: (f = msg.getLastindexedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    nativeid: jspb.Message.getFieldWithDefault(msg, 14, ""),
    uploadsource: jspb.Message.getFieldWithDefault(msg, 15, ""),
    useruploader: (f = msg.getUseruploader()) && proto.com.locusive.api_service.v1.LocusiveUser.toObject(includeInstance, f),
    slackuploader: (f = msg.getSlackuploader()) && proto.com.locusive.api_service.v1.SlackTeamMemberMessage.toObject(includeInstance, f),
    blobstorageurl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    similarityscore: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    parentid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    forcerefresh: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    indexingstartedat: (f = msg.getIndexingstartedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.IndexedDocumentMessage;
  return proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncludeddatasourceid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContainingdocumentdescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addChunkids(value);
      break;
    case 9:
      var value = new proto.com.locusive.api_service.v1.OrganizationMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTokensused(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPercentagecompleted(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastindexedat(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNativeid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadsource(value);
      break;
    case 16:
      var value = new proto.com.locusive.api_service.v1.LocusiveUser;
      reader.readMessage(value,proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinaryFromReader);
      msg.setUseruploader(value);
      break;
    case 17:
      var value = new proto.com.locusive.api_service.v1.SlackTeamMemberMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.SlackTeamMemberMessage.deserializeBinaryFromReader);
      msg.setSlackuploader(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlobstorageurl(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSimilarityscore(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForcerefresh(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIndexingstartedat(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.IndexedDocumentMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIncludeddatasourceid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContainingdocumentdescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getChunkidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.com.locusive.api_service.v1.OrganizationMessage.serializeBinaryToWriter
    );
  }
  f = message.getTokensused();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPercentagecompleted();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLastindexedat();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNativeid();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUploadsource();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getUseruploader();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.com.locusive.api_service.v1.LocusiveUser.serializeBinaryToWriter
    );
  }
  f = message.getSlackuploader();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.com.locusive.api_service.v1.SlackTeamMemberMessage.serializeBinaryToWriter
    );
  }
  f = message.getBlobstorageurl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSimilarityscore();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getForcerefresh();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getIndexingstartedat();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uri = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string includedDataSourceId = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getIncludeddatasourceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setIncludeddatasourceid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string containingDocumentDescription = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getContainingdocumentdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setContainingdocumentdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string chunkIds = 8;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getChunkidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setChunkidsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.addChunkids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearChunkidsList = function() {
  return this.setChunkidsList([]);
};


/**
 * optional OrganizationMessage organization = 9;
 * @return {?proto.com.locusive.api_service.v1.OrganizationMessage}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getOrganization = function() {
  return /** @type{?proto.com.locusive.api_service.v1.OrganizationMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.OrganizationMessage, 9));
};


/**
 * @param {?proto.com.locusive.api_service.v1.OrganizationMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional double tokensUsed = 10;
 * @return {number}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getTokensused = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setTokensused = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string status = 11;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Int32Value percentageCompleted = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getPercentagecompleted = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setPercentagecompleted = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearPercentagecompleted = function() {
  return this.setPercentagecompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.hasPercentagecompleted = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp lastIndexedAt = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getLastindexedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setLastindexedat = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearLastindexedat = function() {
  return this.setLastindexedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.hasLastindexedat = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string nativeId = 14;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getNativeid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setNativeid = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string uploadSource = 15;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getUploadsource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setUploadsource = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional LocusiveUser userUploader = 16;
 * @return {?proto.com.locusive.api_service.v1.LocusiveUser}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getUseruploader = function() {
  return /** @type{?proto.com.locusive.api_service.v1.LocusiveUser} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.LocusiveUser, 16));
};


/**
 * @param {?proto.com.locusive.api_service.v1.LocusiveUser|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setUseruploader = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearUseruploader = function() {
  return this.setUseruploader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.hasUseruploader = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional SlackTeamMemberMessage slackUploader = 17;
 * @return {?proto.com.locusive.api_service.v1.SlackTeamMemberMessage}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getSlackuploader = function() {
  return /** @type{?proto.com.locusive.api_service.v1.SlackTeamMemberMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.SlackTeamMemberMessage, 17));
};


/**
 * @param {?proto.com.locusive.api_service.v1.SlackTeamMemberMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setSlackuploader = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearSlackuploader = function() {
  return this.setSlackuploader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.hasSlackuploader = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string blobStorageUrl = 18;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getBlobstorageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setBlobstorageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional float similarityScore = 19;
 * @return {number}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getSimilarityscore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setSimilarityscore = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional string parentId = 20;
 * @return {string}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool forceRefresh = 21;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getForcerefresh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setForcerefresh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional google.protobuf.Timestamp indexingStartedAt = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getIndexingstartedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setIndexingstartedat = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearIndexingstartedat = function() {
  return this.setIndexingstartedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.hasIndexingstartedat = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp createdAt = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.IndexedDocumentMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 23) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.com.locusive.api_service.v1.IndexedDocumentMessage.toObject(includeInstance, f),
    slackteammemberid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apikey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    slackteamid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    agentid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    slackmessagets: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isslackmessagefromuser: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    usertoken: jspb.Message.getFieldWithDefault(msg, 9, ""),
    slackbotoauthtoken: jspb.Message.getFieldWithDefault(msg, 10, ""),
    agent: (f = msg.getAgent()) && proto.com.locusive.api_service.v1.AgentMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AddDocumentToIndexRequest;
  return proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.IndexedDocumentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackteammemberid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackteamid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackmessagets(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsslackmessagefromuser(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsertoken(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackbotoauthtoken(value);
      break;
    case 11:
      var value = new proto.com.locusive.api_service.v1.AgentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AgentMessage.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.IndexedDocumentMessage.serializeBinaryToWriter
    );
  }
  f = message.getSlackteammemberid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSlackteamid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSlackmessagets();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsslackmessagefromuser();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUsertoken();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSlackbotoauthtoken();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.com.locusive.api_service.v1.AgentMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional IndexedDocumentMessage document = 1;
 * @return {?proto.com.locusive.api_service.v1.IndexedDocumentMessage}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getDocument = function() {
  return /** @type{?proto.com.locusive.api_service.v1.IndexedDocumentMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.IndexedDocumentMessage, 1));
};


/**
 * @param {?proto.com.locusive.api_service.v1.IndexedDocumentMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
*/
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string slackTeamMemberId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getSlackteammemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setSlackteammemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string apiKey = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organizationId = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string slackTeamId = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getSlackteamid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setSlackteamid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string agentId = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string slackMessageTs = 7;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getSlackmessagets = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setSlackmessagets = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isSlackMessageFromUser = 8;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getIsslackmessagefromuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setIsslackmessagefromuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string userToken = 9;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getUsertoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setUsertoken = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string slackbotOauthToken = 10;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getSlackbotoauthtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setSlackbotoauthtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional AgentMessage agent = 11;
 * @return {?proto.com.locusive.api_service.v1.AgentMessage}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.getAgent = function() {
  return /** @type{?proto.com.locusive.api_service.v1.AgentMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.AgentMessage, 11));
};


/**
 * @param {?proto.com.locusive.api_service.v1.AgentMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
*/
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.setAgent = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} returns this
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.clearAgent = function() {
  return this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AddDocumentToIndexRequest.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slackteammemberid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    prompt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxresults: jspb.Message.getFieldWithDefault(msg, 3, 0),
    apikey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    minimumsimilarityscore: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    documentidsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    documentnamesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    organizationid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    agent: (f = msg.getAgent()) && proto.com.locusive.api_service.v1.AgentMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest;
  return proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlackteammemberid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxresults(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinimumsimilarityscore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentids(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentnames(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 9:
      var value = new proto.com.locusive.api_service.v1.AgentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AgentMessage.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlackteammemberid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxresults();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMinimumsimilarityscore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getDocumentidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getDocumentnamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.com.locusive.api_service.v1.AgentMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string slackTeamMemberId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getSlackteammemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setSlackteammemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string prompt = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 maxResults = 3;
 * @return {number}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getMaxresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setMaxresults = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string apiKey = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float minimumSimilarityScore = 5;
 * @return {number}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getMinimumsimilarityscore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setMinimumsimilarityscore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated string documentIds = 6;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getDocumentidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setDocumentidsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.addDocumentids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.clearDocumentidsList = function() {
  return this.setDocumentidsList([]);
};


/**
 * repeated string documentNames = 7;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getDocumentnamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setDocumentnamesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.addDocumentnames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.clearDocumentnamesList = function() {
  return this.setDocumentnamesList([]);
};


/**
 * optional string organizationId = 8;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional AgentMessage agent = 9;
 * @return {?proto.com.locusive.api_service.v1.AgentMessage}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.getAgent = function() {
  return /** @type{?proto.com.locusive.api_service.v1.AgentMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.AgentMessage, 9));
};


/**
 * @param {?proto.com.locusive.api_service.v1.AgentMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
*/
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.setAgent = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.clearAgent = function() {
  return this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    matchesList: jspb.Message.toObjectList(msg.getMatchesList(),
    proto.com.locusive.api_service.v1.IndexedDocumentMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse;
  return proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.IndexedDocumentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinaryFromReader);
      msg.addMatches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatchesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.IndexedDocumentMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IndexedDocumentMessage matches = 1;
 * @return {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.prototype.getMatchesList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.IndexedDocumentMessage, 1));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} value
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse} returns this
*/
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.prototype.setMatchesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage}
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.prototype.addMatches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.locusive.api_service.v1.IndexedDocumentMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse} returns this
 */
proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.prototype.clearMatchesList = function() {
  return this.setMatchesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    progress: (f = msg.getProgress()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest;
  return proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Int32Value progress = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.getProgress = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} returns this
*/
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.setProgress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.clearProgress = function() {
  return this.setProgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest.prototype.hasProgress = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    chunkidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    tokensused: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest;
  return proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addChunkids(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTokensused(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChunkidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTokensused();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string chunkIds = 2;
 * @return {!Array<string>}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.getChunkidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.setChunkidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.addChunkids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.clearChunkidsList = function() {
  return this.setChunkidsList([]);
};


/**
 * optional double tokensUsed = 3;
 * @return {number}
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.getTokensused = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} returns this
 */
proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest.prototype.setTokensused = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ListDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ListDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apikey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sortfield: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sortdirection: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ListDocumentsRequest}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ListDocumentsRequest;
  return proto.com.locusive.api_service.v1.ListDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ListDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ListDocumentsRequest}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortfield(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortdirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ListDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ListDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSortfield();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSortdirection();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string apiKey = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ListDocumentsRequest} returns this
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.ListDocumentsRequest} returns this
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 offset = 3;
 * @return {number}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.ListDocumentsRequest} returns this
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sortField = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.getSortfield = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ListDocumentsRequest} returns this
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.setSortfield = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sortDirection = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.getSortdirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ListDocumentsRequest} returns this
 */
proto.com.locusive.api_service.v1.ListDocumentsRequest.prototype.setSortdirection = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.IndexedDocumentListMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentListMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.com.locusive.api_service.v1.IndexedDocumentMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentListMessage}
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.IndexedDocumentListMessage;
  return proto.com.locusive.api_service.v1.IndexedDocumentListMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentListMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentListMessage}
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.locusive.api_service.v1.IndexedDocumentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.IndexedDocumentListMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentListMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.locusive.api_service.v1.IndexedDocumentMessage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IndexedDocumentMessage documents = 1;
 * @return {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>}
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.IndexedDocumentMessage, 1));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>} value
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentListMessage} returns this
*/
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.IndexedDocumentMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentMessage}
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.locusive.api_service.v1.IndexedDocumentMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.IndexedDocumentListMessage} returns this
 */
proto.com.locusive.api_service.v1.IndexedDocumentListMessage.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: msg.getContent_asB64(),
    organizationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filemimetype: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.UploadAndIndexFileRequest;
  return proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilemimetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilemimetype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string fileName = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} returns this
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes content = 2;
 * @return {!(string|Uint8Array)}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} returns this
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional string organizationId = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} returns this
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fileMimeType = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.getFilemimetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} returns this
 */
proto.com.locusive.api_service.v1.UploadAndIndexFileRequest.prototype.setFilemimetype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ApiKeyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ApiKeyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ApiKeyMessage;
  return proto.com.locusive.api_service.v1.ApiKeyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ApiKeyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ApiKeyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ApiKeyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage} returns this
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage} returns this
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationId = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage} returns this
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage} returns this
*/
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ApiKeyMessage} returns this
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ApiKeyMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SchemaColumnMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SchemaColumnMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeddatasourceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datatype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    foreignkeytablename: jspb.Message.getFieldWithDefault(msg, 5, ""),
    foreignkeycolumnname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isincluded: (f = msg.getIsincluded()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SchemaColumnMessage;
  return proto.com.locusive.api_service.v1.SchemaColumnMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SchemaColumnMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncludeddatasourceid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatatype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignkeytablename(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignkeycolumnname(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsincluded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SchemaColumnMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SchemaColumnMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeddatasourceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatatype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getForeignkeytablename();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getForeignkeycolumnname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsincluded();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string includedDataSourceId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getIncludeddatasourceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setIncludeddatasourceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dataType = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getDatatype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setDatatype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string foreignKeyTableName = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getForeignkeytablename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setForeignkeytablename = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string foreignKeyColumnName = 6;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getForeignkeycolumnname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setForeignkeycolumnname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
*/
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
*/
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue isIncluded = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.getIsincluded = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
*/
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.setIsincluded = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.SchemaColumnMessage} returns this
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.clearIsincluded = function() {
  return this.setIsincluded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.SchemaColumnMessage.prototype.hasIsincluded = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ReferenceGuideMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    toolname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ReferenceGuideMessage;
  return proto.com.locusive.api_service.v1.ReferenceGuideMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToolname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ReferenceGuideMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToolname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organizationId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string toolName = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.getToolname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.setToolname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
*/
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
*/
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ReferenceGuideMessage} returns this
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ReferenceGuideMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authorizedintegrationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    question: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sql: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    similarityscore: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    istemplate: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage;
  return proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizedintegrationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSql(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSimilarityscore(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIstemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthorizedintegrationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSql();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSimilarityscore();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getIstemplate();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string authorizedIntegrationId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getAuthorizedintegrationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setAuthorizedintegrationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string question = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getQuestion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setQuestion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sql = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getSql = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setSql = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
*/
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
*/
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double similarityScore = 8;
 * @return {number}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getSimilarityscore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setSimilarityscore = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool isTemplate = 9;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.getIstemplate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} returns this
 */
proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.prototype.setIstemplate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.TrainingInstructionMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scenariodescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instructionalcontent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    similarityscore: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    agentid: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.TrainingInstructionMessage;
  return proto.com.locusive.api_service.v1.TrainingInstructionMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScenariodescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstructionalcontent(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSimilarityscore(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.TrainingInstructionMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScenariodescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstructionalcontent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSimilarityscore();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organizationId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scenarioDescription = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getScenariodescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setScenariodescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instructionalContent = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getInstructionalcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setInstructionalcontent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
*/
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
*/
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double similarityScore = 7;
 * @return {number}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getSimilarityscore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setSimilarityscore = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string agentId = 8;
 * @return {string}
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} returns this
 */
proto.com.locusive.api_service.v1.TrainingInstructionMessage.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SummaryOfProcessMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SummaryOfProcessMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SummaryOfProcessMessage}
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SummaryOfProcessMessage;
  return proto.com.locusive.api_service.v1.SummaryOfProcessMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SummaryOfProcessMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SummaryOfProcessMessage}
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SummaryOfProcessMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SummaryOfProcessMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string summary = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SummaryOfProcessMessage} returns this
 */
proto.com.locusive.api_service.v1.SummaryOfProcessMessage.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.SourceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.SourceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SourceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.SourceMessage}
 */
proto.com.locusive.api_service.v1.SourceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.SourceMessage;
  return proto.com.locusive.api_service.v1.SourceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.SourceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.SourceMessage}
 */
proto.com.locusive.api_service.v1.SourceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.SourceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.SourceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.SourceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SourceMessage} returns this
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SourceMessage} returns this
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SourceMessage} returns this
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string imageUrl = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.SourceMessage} returns this
 */
proto.com.locusive.api_service.v1.SourceMessage.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AgentExecutionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    agent: (f = msg.getAgent()) && proto.com.locusive.api_service.v1.AgentMessage.toObject(includeInstance, f),
    systemprompt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.com.locusive.api_service.v1.GenericChatMessage.toObject, includeInstance),
    statusupdateparametercollection: (f = msg.getStatusupdateparametercollection()) && proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.toObject(includeInstance, f),
    apikey: jspb.Message.getFieldWithDefault(msg, 8, ""),
    llmmodeltype: jspb.Message.getFieldWithDefault(msg, 9, ""),
    playbookid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    sharedsecret: jspb.Message.getFieldWithDefault(msg, 11, ""),
    currentdocumentcontent: jspb.Message.getFieldWithDefault(msg, 12, ""),
    useridincustomerapp: jspb.Message.getFieldWithDefault(msg, 13, ""),
    usernameincustomerapp: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AgentExecutionRequest;
  return proto.com.locusive.api_service.v1.AgentExecutionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationid(value);
      break;
    case 4:
      var value = new proto.com.locusive.api_service.v1.AgentMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.AgentMessage.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemprompt(value);
      break;
    case 6:
      var value = new proto.com.locusive.api_service.v1.GenericChatMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.GenericChatMessage.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    case 7:
      var value = new proto.com.locusive.api_service.v1.StatusUpdateParameterCollection;
      reader.readMessage(value,proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.deserializeBinaryFromReader);
      msg.setStatusupdateparametercollection(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setApikey(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLlmmodeltype(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaybookid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharedsecret(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentdocumentcontent(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseridincustomerapp(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsernameincustomerapp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AgentExecutionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.locusive.api_service.v1.AgentMessage.serializeBinaryToWriter
    );
  }
  f = message.getSystemprompt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.locusive.api_service.v1.GenericChatMessage.serializeBinaryToWriter
    );
  }
  f = message.getStatusupdateparametercollection();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.serializeBinaryToWriter
    );
  }
  f = message.getApikey();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLlmmodeltype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlaybookid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSharedsecret();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCurrentdocumentcontent();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUseridincustomerapp();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUsernameincustomerapp();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organizationId = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getOrganizationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setOrganizationid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AgentMessage agent = 4;
 * @return {?proto.com.locusive.api_service.v1.AgentMessage}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getAgent = function() {
  return /** @type{?proto.com.locusive.api_service.v1.AgentMessage} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.AgentMessage, 4));
};


/**
 * @param {?proto.com.locusive.api_service.v1.AgentMessage|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
*/
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setAgent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.clearAgent = function() {
  return this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string systemPrompt = 5;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getSystemprompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setSystemprompt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated GenericChatMessage messages = 6;
 * @return {!Array<!proto.com.locusive.api_service.v1.GenericChatMessage>}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.GenericChatMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.GenericChatMessage, 6));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.GenericChatMessage>} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
*/
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.GenericChatMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.GenericChatMessage}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.locusive.api_service.v1.GenericChatMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};


/**
 * optional StatusUpdateParameterCollection statusUpdateParameterCollection = 7;
 * @return {?proto.com.locusive.api_service.v1.StatusUpdateParameterCollection}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getStatusupdateparametercollection = function() {
  return /** @type{?proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} */ (
    jspb.Message.getWrapperField(this, proto.com.locusive.api_service.v1.StatusUpdateParameterCollection, 7));
};


/**
 * @param {?proto.com.locusive.api_service.v1.StatusUpdateParameterCollection|undefined} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
*/
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setStatusupdateparametercollection = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.clearStatusupdateparametercollection = function() {
  return this.setStatusupdateparametercollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.hasStatusupdateparametercollection = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string apiKey = 8;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getApikey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setApikey = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string llmModelType = 9;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getLlmmodeltype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setLlmmodeltype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string playbookId = 10;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getPlaybookid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setPlaybookid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sharedSecret = 11;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getSharedsecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setSharedsecret = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string currentDocumentContent = 12;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getCurrentdocumentcontent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setCurrentdocumentcontent = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string userIdInCustomerApp = 13;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getUseridincustomerapp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setUseridincustomerapp = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string userNameInCustomerApp = 14;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.getUsernameincustomerapp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionRequest} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionRequest.prototype.setUsernameincustomerapp = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.AgentExecutionResultMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    response: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusmessageid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hassummaryofprocess: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.com.locusive.api_service.v1.SourceMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.AgentExecutionResultMessage;
  return proto.com.locusive.api_service.v1.AgentExecutionResultMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusmessageid(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHassummaryofprocess(value);
      break;
    case 5:
      var value = new proto.com.locusive.api_service.v1.SourceMessage;
      reader.readMessage(value,proto.com.locusive.api_service.v1.SourceMessage.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.AgentExecutionResultMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusmessageid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHassummaryofprocess();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.locusive.api_service.v1.SourceMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string response = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string statusMessageId = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.getStatusmessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.setStatusmessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool hasSummaryOfProcess = 4;
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.getHassummaryofprocess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.setHassummaryofprocess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated SourceMessage sources = 5;
 * @return {!Array<!proto.com.locusive.api_service.v1.SourceMessage>}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.com.locusive.api_service.v1.SourceMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.locusive.api_service.v1.SourceMessage, 5));
};


/**
 * @param {!Array<!proto.com.locusive.api_service.v1.SourceMessage>} value
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} returns this
*/
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SourceMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.locusive.api_service.v1.SourceMessage}
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.locusive.api_service.v1.SourceMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.locusive.api_service.v1.AgentExecutionResultMessage} returns this
 */
proto.com.locusive.api_service.v1.AgentExecutionResultMessage.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.GenericChatMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.GenericChatMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GenericChatMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    role: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.GenericChatMessage}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.GenericChatMessage;
  return proto.com.locusive.api_service.v1.GenericChatMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.GenericChatMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.GenericChatMessage}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.GenericChatMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.GenericChatMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.GenericChatMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GenericChatMessage} returns this
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.GenericChatMessage} returns this
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.locusive.api_service.v1.GenericChatMessage} returns this
*/
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.locusive.api_service.v1.GenericChatMessage} returns this
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.locusive.api_service.v1.GenericChatMessage.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messagets: jspb.Message.getFieldWithDefault(msg, 2, ""),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statusmessageid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.locusive.api_service.v1.StatusUpdateParameterCollection;
  return proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagets(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusmessageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessagets();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatusmessageid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string agentId = 1;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.getAgentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} returns this
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.setAgentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string messageTs = 2;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.getMessagets = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} returns this
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.setMessagets = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} returns this
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string statusMessageId = 4;
 * @return {string}
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.getStatusmessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.locusive.api_service.v1.StatusUpdateParameterCollection} returns this
 */
proto.com.locusive.api_service.v1.StatusUpdateParameterCollection.prototype.setStatusmessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.com.locusive.api_service.v1.TriggerTypeMessage = {
  KEYWORD: 0,
  TOPIC: 1,
  KEYWORDANDTOPIC: 2,
  SCHEDULE: 3
};

goog.object.extend(exports, proto.com.locusive.api_service.v1);
