/**
 * @fileoverview gRPC-Web generated client stub for com.locusive.api_service.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.22.2
// source: src/generated/api_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.com = {};
proto.com.locusive = {};
proto.com.locusive.api_service = {};
proto.com.locusive.api_service.v1 = require('./api_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.locusive.api_service.v1.ApiServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.HealthCheckRequest,
 *   !proto.com.locusive.api_service.v1.HealthCheckResponse>}
 */
const methodDescriptor_ApiService_HealthCheck = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/HealthCheck',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.HealthCheckRequest,
  proto.com.locusive.api_service.v1.HealthCheckResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.HealthCheckRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.HealthCheckResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.HealthCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.HealthCheckResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.HealthCheckResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.healthCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/HealthCheck',
      request,
      metadata || {},
      methodDescriptor_ApiService_HealthCheck,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.HealthCheckRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.HealthCheckResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.healthCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/HealthCheck',
      request,
      metadata || {},
      methodDescriptor_ApiService_HealthCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.CreateUserRequest,
 *   !proto.com.locusive.api_service.v1.CreateUserResponse>}
 */
const methodDescriptor_ApiService_CreateUser = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.CreateUserRequest,
  proto.com.locusive.api_service.v1.CreateUserResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.CreateUserResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.CreateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.CreateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateUser,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.CreateUserResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SlackTeamMemberMessage,
 *   !proto.com.locusive.api_service.v1.LocusiveUser>}
 */
const methodDescriptor_ApiService_CreateUserAndOrganizationMemberFromSlackTeamMember = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CreateUserAndOrganizationMemberFromSlackTeamMember',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SlackTeamMemberMessage,
  proto.com.locusive.api_service.v1.LocusiveUser,
  /**
   * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.LocusiveUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.LocusiveUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.createUserAndOrganizationMemberFromSlackTeamMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateUserAndOrganizationMemberFromSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateUserAndOrganizationMemberFromSlackTeamMember,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.LocusiveUser>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.createUserAndOrganizationMemberFromSlackTeamMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateUserAndOrganizationMemberFromSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateUserAndOrganizationMemberFromSlackTeamMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.GetUserRequest,
 *   !proto.com.locusive.api_service.v1.LocusiveUser>}
 */
const methodDescriptor_ApiService_GetUser = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.GetUserRequest,
  proto.com.locusive.api_service.v1.LocusiveUser,
  /**
   * @param {!proto.com.locusive.api_service.v1.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.LocusiveUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.LocusiveUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetUser',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetUser,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.LocusiveUser>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetUser',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.LocusiveUser,
 *   !proto.com.locusive.api_service.v1.UpdateUserResponse>}
 */
const methodDescriptor_ApiService_UpdateUser = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.LocusiveUser,
  proto.com.locusive.api_service.v1.UpdateUserResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.LocusiveUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.UpdateUserResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.LocusiveUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.UpdateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.UpdateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateUser,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.LocusiveUser} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.UpdateUserResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.CompleteAccountSetupRequest,
 *   !proto.com.locusive.api_service.v1.LocusiveUser>}
 */
const methodDescriptor_ApiService_CompleteAccountSetup = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CompleteAccountSetup',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.CompleteAccountSetupRequest,
  proto.com.locusive.api_service.v1.LocusiveUser,
  /**
   * @param {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.LocusiveUser.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.LocusiveUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.LocusiveUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.completeAccountSetup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CompleteAccountSetup',
      request,
      metadata || {},
      methodDescriptor_ApiService_CompleteAccountSetup,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.CompleteAccountSetupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.LocusiveUser>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.completeAccountSetup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CompleteAccountSetup',
      request,
      metadata || {},
      methodDescriptor_ApiService_CompleteAccountSetup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AcceptInvitationRequest,
 *   !proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
const methodDescriptor_ApiService_AcceptInvitation = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/AcceptInvitation',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AcceptInvitationRequest,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.AcceptInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AcceptInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.acceptInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AcceptInvitation',
      request,
      metadata || {},
      methodDescriptor_ApiService_AcceptInvitation,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AcceptInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.acceptInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AcceptInvitation',
      request,
      metadata || {},
      methodDescriptor_ApiService_AcceptInvitation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.CreateOrganizationRequest,
 *   !proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
const methodDescriptor_ApiService_CreateOrganization = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CreateOrganization',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.CreateOrganizationRequest,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.CreateOrganizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.CreateOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.createOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateOrganization,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.CreateOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.createOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.InviteTeamMembersRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_InviteTeamMembers = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/InviteTeamMembers',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.InviteTeamMembersRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.inviteTeamMembers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/InviteTeamMembers',
      request,
      metadata || {},
      methodDescriptor_ApiService_InviteTeamMembers,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.InviteTeamMembersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.inviteTeamMembers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/InviteTeamMembers',
      request,
      metadata || {},
      methodDescriptor_ApiService_InviteTeamMembers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
const methodDescriptor_ApiService_GetOrganization = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetOrganization',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganization,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
const methodDescriptor_ApiService_GetOrganizationByApiKey = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetOrganizationByApiKey',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getOrganizationByApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationByApiKey',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationByApiKey,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getOrganizationByApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationByApiKey',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationByApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.OrganizationsMessage>}
 */
const methodDescriptor_ApiService_GetOrganizationsByUserId = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetOrganizationsByUserId',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.OrganizationsMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationsMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationsMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationsMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getOrganizationsByUserId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationsByUserId',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationsByUserId,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationsMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getOrganizationsByUserId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationsByUserId',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationsByUserId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
const methodDescriptor_ApiService_GetOrganizationByAssistantEmailAddress = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetOrganizationByAssistantEmailAddress',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getOrganizationByAssistantEmailAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationByAssistantEmailAddress',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationByAssistantEmailAddress,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getOrganizationByAssistantEmailAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationByAssistantEmailAddress',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationByAssistantEmailAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.RemoveMemberRequest,
 *   !proto.com.locusive.api_service.v1.RemoveMemberResponse>}
 */
const methodDescriptor_ApiService_RemoveMemberFromOrganization = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RemoveMemberFromOrganization',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.RemoveMemberRequest,
  proto.com.locusive.api_service.v1.RemoveMemberResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.RemoveMemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.RemoveMemberResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.RemoveMemberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.RemoveMemberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.removeMemberFromOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveMemberFromOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveMemberFromOrganization,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.RemoveMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.RemoveMemberResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.removeMemberFromOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveMemberFromOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveMemberFromOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.OrganizationMessage,
 *   !proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
const methodDescriptor_ApiService_UpdateOrganization = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateOrganization',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.OrganizationMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.OrganizationMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateOrganization,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.OrganizationMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.UsageMetricsMessage>}
 */
const methodDescriptor_ApiService_GetUsageMetrics = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetUsageMetrics',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.UsageMetricsMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.UsageMetricsMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.UsageMetricsMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.UsageMetricsMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getUsageMetrics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetUsageMetrics,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.UsageMetricsMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getUsageMetrics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetUsageMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AddIntegrationRequest,
 *   !proto.com.locusive.api_service.v1.AddIntegrationResponse>}
 */
const methodDescriptor_ApiService_AddIntegration = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/AddIntegration',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AddIntegrationRequest,
  proto.com.locusive.api_service.v1.AddIntegrationResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.AddIntegrationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AddIntegrationResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AddIntegrationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AddIntegrationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.addIntegration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AddIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_AddIntegration,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AddIntegrationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AddIntegrationResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.addIntegration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AddIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_AddIntegration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.IncludedDataSourceMessage,
 *   !proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 */
const methodDescriptor_ApiService_AddOrUpdateIncludedDataSource = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/AddOrUpdateIncludedDataSource',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.IncludedDataSourceMessage,
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.addOrUpdateIncludedDataSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AddOrUpdateIncludedDataSource',
      request,
      metadata || {},
      methodDescriptor_ApiService_AddOrUpdateIncludedDataSource,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.IncludedDataSourceMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.addOrUpdateIncludedDataSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AddOrUpdateIncludedDataSource',
      request,
      metadata || {},
      methodDescriptor_ApiService_AddOrUpdateIncludedDataSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 */
const methodDescriptor_ApiService_GetAuthorizedIntegration = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetAuthorizedIntegration',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getAuthorizedIntegration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetAuthorizedIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetAuthorizedIntegration,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getAuthorizedIntegration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetAuthorizedIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetAuthorizedIntegration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse>}
 */
const methodDescriptor_ApiService_GetIntegrationsForOrganization = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetIntegrationsForOrganization',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getIntegrationsForOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetIntegrationsForOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetIntegrationsForOrganization,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationIntegrationsResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getIntegrationsForOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetIntegrationsForOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetIntegrationsForOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_RemoveIntegration = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RemoveIntegration',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.removeIntegration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveIntegration,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.removeIntegration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveIntegration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 */
const methodDescriptor_ApiService_RemoveIncludedDataSource = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RemoveIncludedDataSource',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.removeIncludedDataSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveIncludedDataSource',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveIncludedDataSource,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.removeIncludedDataSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveIncludedDataSource',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveIncludedDataSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 */
const methodDescriptor_ApiService_RefreshCustomerDatabaseSchema = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RefreshCustomerDatabaseSchema',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.refreshCustomerDatabaseSchema =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RefreshCustomerDatabaseSchema',
      request,
      metadata || {},
      methodDescriptor_ApiService_RefreshCustomerDatabaseSchema,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.refreshCustomerDatabaseSchema =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RefreshCustomerDatabaseSchema',
      request,
      metadata || {},
      methodDescriptor_ApiService_RefreshCustomerDatabaseSchema);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_UpsertReferenceGuideForAuthorizedIntegration = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpsertReferenceGuideForAuthorizedIntegration',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.upsertReferenceGuideForAuthorizedIntegration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpsertReferenceGuideForAuthorizedIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpsertReferenceGuideForAuthorizedIntegration,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AuthorizedIntegrationMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.upsertReferenceGuideForAuthorizedIntegration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpsertReferenceGuideForAuthorizedIntegration',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpsertReferenceGuideForAuthorizedIntegration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SchemaColumnMessage,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_UpdateSchemaColumn = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateSchemaColumn',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SchemaColumnMessage,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SchemaColumnMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SchemaColumnMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateSchemaColumn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateSchemaColumn',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateSchemaColumn,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SchemaColumnMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateSchemaColumn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateSchemaColumn',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateSchemaColumn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SlackTeamMemberMessage,
 *   !proto.com.locusive.api_service.v1.OrganizationMessage>}
 */
const methodDescriptor_ApiService_GetOrganizationForSlackTeamMember = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetOrganizationForSlackTeamMember',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SlackTeamMemberMessage,
  proto.com.locusive.api_service.v1.OrganizationMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.OrganizationMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.OrganizationMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.OrganizationMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getOrganizationForSlackTeamMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationForSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationForSlackTeamMember,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SlackTeamMemberMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.OrganizationMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getOrganizationForSlackTeamMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetOrganizationForSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetOrganizationForSlackTeamMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SlackOauthTokenResponse>}
 */
const methodDescriptor_ApiService_GetSlackOauthToken = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetSlackOauthToken',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SlackOauthTokenResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SlackOauthTokenResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SlackOauthTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SlackOauthTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getSlackOauthToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetSlackOauthToken',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetSlackOauthToken,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SlackOauthTokenResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getSlackOauthToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetSlackOauthToken',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetSlackOauthToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_RemoveSlackFromOrganization = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RemoveSlackFromOrganization',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.removeSlackFromOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveSlackFromOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveSlackFromOrganization,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.removeSlackFromOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RemoveSlackFromOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_RemoveSlackFromOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_RegisterSlackTeamMember = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RegisterSlackTeamMember',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.registerSlackTeamMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RegisterSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_RegisterSlackTeamMember,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.RegisterSlackTeamMemberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.registerSlackTeamMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RegisterSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_RegisterSlackTeamMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest,
 *   !proto.com.locusive.api_service.v1.SlackTeamMemberMessage>}
 */
const methodDescriptor_ApiService_GetSlackTeamMemberFromSlackUserAndTeamId = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetSlackTeamMemberFromSlackUserAndTeamId',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest,
  proto.com.locusive.api_service.v1.SlackTeamMemberMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SlackTeamMemberMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SlackTeamMemberMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SlackTeamMemberMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getSlackTeamMemberFromSlackUserAndTeamId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetSlackTeamMemberFromSlackUserAndTeamId',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetSlackTeamMemberFromSlackUserAndTeamId,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.GetSlackTeamMemberFromSlackUserAndTeamIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SlackTeamMemberMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getSlackTeamMemberFromSlackUserAndTeamId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetSlackTeamMemberFromSlackUserAndTeamId',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetSlackTeamMemberFromSlackUserAndTeamId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_MarkOauthTokenAsRevokedForSlackTeamMember = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/MarkOauthTokenAsRevokedForSlackTeamMember',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.markOauthTokenAsRevokedForSlackTeamMember =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/MarkOauthTokenAsRevokedForSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_MarkOauthTokenAsRevokedForSlackTeamMember,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.markOauthTokenAsRevokedForSlackTeamMember =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/MarkOauthTokenAsRevokedForSlackTeamMember',
      request,
      metadata || {},
      methodDescriptor_ApiService_MarkOauthTokenAsRevokedForSlackTeamMember);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_MarkSlackTeamMemberAsUnableToRegister = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/MarkSlackTeamMemberAsUnableToRegister',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.markSlackTeamMemberAsUnableToRegister =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/MarkSlackTeamMemberAsUnableToRegister',
      request,
      metadata || {},
      methodDescriptor_ApiService_MarkSlackTeamMemberAsUnableToRegister,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.markSlackTeamMemberAsUnableToRegister =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/MarkSlackTeamMemberAsUnableToRegister',
      request,
      metadata || {},
      methodDescriptor_ApiService_MarkSlackTeamMemberAsUnableToRegister);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AddDocumentToIndexRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_AddDocumentToIndex = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/AddDocumentToIndex',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AddDocumentToIndexRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.addDocumentToIndex =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AddDocumentToIndex',
      request,
      metadata || {},
      methodDescriptor_ApiService_AddDocumentToIndex,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AddDocumentToIndexRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.addDocumentToIndex =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/AddDocumentToIndex',
      request,
      metadata || {},
      methodDescriptor_ApiService_AddDocumentToIndex);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest,
 *   !proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse>}
 */
const methodDescriptor_ApiService_GetTopDocumentMatches = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetTopDocumentMatches',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest,
  proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getTopDocumentMatches =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetTopDocumentMatches',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetTopDocumentMatches,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.GetTopDocumentMatchesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.GetTopDocumentMatchesResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getTopDocumentMatches =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetTopDocumentMatches',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetTopDocumentMatches);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_UpdateIndexedDocumentStatus = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateIndexedDocumentStatus',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateIndexedDocumentStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateIndexedDocumentStatus',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateIndexedDocumentStatus,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateIndexedDocumentStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateIndexedDocumentStatus',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateIndexedDocumentStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_UpdateIndexedDocumentAfterRefresh = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateIndexedDocumentAfterRefresh',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateIndexedDocumentAfterRefresh =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateIndexedDocumentAfterRefresh',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateIndexedDocumentAfterRefresh,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.UpdateIndexedDocumentAfterRefreshRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateIndexedDocumentAfterRefresh =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateIndexedDocumentAfterRefresh',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateIndexedDocumentAfterRefresh);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.IndexedDocumentMessage>}
 */
const methodDescriptor_ApiService_GetIndexedDocumentById = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetIndexedDocumentById',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.IndexedDocumentMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.IndexedDocumentMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.IndexedDocumentMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getIndexedDocumentById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetIndexedDocumentById',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetIndexedDocumentById,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.IndexedDocumentMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getIndexedDocumentById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetIndexedDocumentById',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetIndexedDocumentById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_DeleteIndexedDocument = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/DeleteIndexedDocument',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.deleteIndexedDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteIndexedDocument',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteIndexedDocument,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.deleteIndexedDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteIndexedDocument',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteIndexedDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdsRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_DeleteIndexedDocuments = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/DeleteIndexedDocuments',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdsRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.deleteIndexedDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteIndexedDocuments',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteIndexedDocuments,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.deleteIndexedDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteIndexedDocuments',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteIndexedDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.ListDocumentsRequest,
 *   !proto.com.locusive.api_service.v1.IndexedDocumentListMessage>}
 */
const methodDescriptor_ApiService_ListDocuments = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/ListDocuments',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.ListDocumentsRequest,
  proto.com.locusive.api_service.v1.IndexedDocumentListMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.ListDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.IndexedDocumentListMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.ListDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.IndexedDocumentListMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.IndexedDocumentListMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.listDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_ApiService_ListDocuments,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ListDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.IndexedDocumentListMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.listDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/ListDocuments',
      request,
      metadata || {},
      methodDescriptor_ApiService_ListDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.IndexedDocumentListMessage>}
 */
const methodDescriptor_ApiService_GetAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.IndexedDocumentListMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.IndexedDocumentListMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.IndexedDocumentListMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.IndexedDocumentListMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.IndexedDocumentListMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetAllIndexedDocumentsWithoutAuthorizedIntegrationForOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.UploadAndIndexFileRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_UploadAndIndexFile = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UploadAndIndexFile',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.UploadAndIndexFileRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.uploadAndIndexFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UploadAndIndexFile',
      request,
      metadata || {},
      methodDescriptor_ApiService_UploadAndIndexFile,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.UploadAndIndexFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.uploadAndIndexFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UploadAndIndexFile',
      request,
      metadata || {},
      methodDescriptor_ApiService_UploadAndIndexFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_GetPublicUrlForUploadedDocument = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetPublicUrlForUploadedDocument',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getPublicUrlForUploadedDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetPublicUrlForUploadedDocument',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetPublicUrlForUploadedDocument,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getPublicUrlForUploadedDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetPublicUrlForUploadedDocument',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetPublicUrlForUploadedDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_RefreshIndexedDocument = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RefreshIndexedDocument',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.refreshIndexedDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RefreshIndexedDocument',
      request,
      metadata || {},
      methodDescriptor_ApiService_RefreshIndexedDocument,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.refreshIndexedDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RefreshIndexedDocument',
      request,
      metadata || {},
      methodDescriptor_ApiService_RefreshIndexedDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_RefreshAllIndexedDocumentsForIncludedDataSource = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/RefreshAllIndexedDocumentsForIncludedDataSource',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.refreshAllIndexedDocumentsForIncludedDataSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RefreshAllIndexedDocumentsForIncludedDataSource',
      request,
      metadata || {},
      methodDescriptor_ApiService_RefreshAllIndexedDocumentsForIncludedDataSource,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.refreshAllIndexedDocumentsForIncludedDataSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/RefreshAllIndexedDocumentsForIncludedDataSource',
      request,
      metadata || {},
      methodDescriptor_ApiService_RefreshAllIndexedDocumentsForIncludedDataSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_MarkIndexedDocumentAsInaccessible = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/MarkIndexedDocumentAsInaccessible',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.markIndexedDocumentAsInaccessible =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/MarkIndexedDocumentAsInaccessible',
      request,
      metadata || {},
      methodDescriptor_ApiService_MarkIndexedDocumentAsInaccessible,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.markIndexedDocumentAsInaccessible =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/MarkIndexedDocumentAsInaccessible',
      request,
      metadata || {},
      methodDescriptor_ApiService_MarkIndexedDocumentAsInaccessible);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.ApiKeyMessage,
 *   !proto.com.locusive.api_service.v1.ApiKeyMessage>}
 */
const methodDescriptor_ApiService_CreateApiKey = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CreateApiKey',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.ApiKeyMessage,
  proto.com.locusive.api_service.v1.ApiKeyMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.ApiKeyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.ApiKeyMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.ApiKeyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.ApiKeyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.ApiKeyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.createApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateApiKey',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateApiKey,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ApiKeyMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.ApiKeyMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.createApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateApiKey',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_DeleteApiKey = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/DeleteApiKey',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.deleteApiKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteApiKey',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteApiKey,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.deleteApiKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteApiKey',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteApiKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.TrainingInstructionMessage,
 *   !proto.com.locusive.api_service.v1.TrainingInstructionMessage>}
 */
const methodDescriptor_ApiService_CreateTrainingInstruction = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CreateTrainingInstruction',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.TrainingInstructionMessage,
  proto.com.locusive.api_service.v1.TrainingInstructionMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.TrainingInstructionMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.TrainingInstructionMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.createTrainingInstruction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateTrainingInstruction',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateTrainingInstruction,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.createTrainingInstruction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateTrainingInstruction',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateTrainingInstruction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.TrainingInstructionMessage,
 *   !proto.com.locusive.api_service.v1.TrainingInstructionMessage>}
 */
const methodDescriptor_ApiService_UpdateTrainingInstruction = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateTrainingInstruction',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.TrainingInstructionMessage,
  proto.com.locusive.api_service.v1.TrainingInstructionMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.TrainingInstructionMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.TrainingInstructionMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateTrainingInstruction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateTrainingInstruction',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateTrainingInstruction,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.TrainingInstructionMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.TrainingInstructionMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateTrainingInstruction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateTrainingInstruction',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateTrainingInstruction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_DeleteTrainingInstruction = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/DeleteTrainingInstruction',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.deleteTrainingInstruction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteTrainingInstruction',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteTrainingInstruction,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.deleteTrainingInstruction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteTrainingInstruction',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteTrainingInstruction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage,
 *   !proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>}
 */
const methodDescriptor_ApiService_CreateExampleDatabaseQuery = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CreateExampleDatabaseQuery',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage,
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.createExampleDatabaseQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateExampleDatabaseQuery',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateExampleDatabaseQuery,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.createExampleDatabaseQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateExampleDatabaseQuery',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateExampleDatabaseQuery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage,
 *   !proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>}
 */
const methodDescriptor_ApiService_UpdateExampleDatabaseQuery = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateExampleDatabaseQuery',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage,
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateExampleDatabaseQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateExampleDatabaseQuery',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateExampleDatabaseQuery,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.ExampleDatabaseQueryMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateExampleDatabaseQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateExampleDatabaseQuery',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateExampleDatabaseQuery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_DeleteExampleDatabaseQuery = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/DeleteExampleDatabaseQuery',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.deleteExampleDatabaseQuery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteExampleDatabaseQuery',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteExampleDatabaseQuery,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.deleteExampleDatabaseQuery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/DeleteExampleDatabaseQuery',
      request,
      metadata || {},
      methodDescriptor_ApiService_DeleteExampleDatabaseQuery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AgentExecutionRequest,
 *   !proto.com.locusive.api_service.v1.AgentExecutionResultMessage>}
 */
const methodDescriptor_ApiService_HandleAgentExecutionRequest = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/HandleAgentExecutionRequest',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AgentExecutionRequest,
  proto.com.locusive.api_service.v1.AgentExecutionResultMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AgentExecutionResultMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AgentExecutionResultMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AgentExecutionResultMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.handleAgentExecutionRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/HandleAgentExecutionRequest',
      request,
      metadata || {},
      methodDescriptor_ApiService_HandleAgentExecutionRequest,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AgentExecutionResultMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.handleAgentExecutionRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/HandleAgentExecutionRequest',
      request,
      metadata || {},
      methodDescriptor_ApiService_HandleAgentExecutionRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AgentExecutionRequest,
 *   !proto.com.locusive.api_service.v1.AgentExecutionResultMessage>}
 */
const methodDescriptor_ApiService_HandleAgentExecutionRequestStream = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/HandleAgentExecutionRequestStream',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.com.locusive.api_service.v1.AgentExecutionRequest,
  proto.com.locusive.api_service.v1.AgentExecutionResultMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AgentExecutionResultMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AgentExecutionResultMessage>}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.handleAgentExecutionRequestStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/HandleAgentExecutionRequestStream',
      request,
      metadata || {},
      methodDescriptor_ApiService_HandleAgentExecutionRequestStream);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AgentExecutionRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AgentExecutionResultMessage>}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.handleAgentExecutionRequestStream =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/HandleAgentExecutionRequestStream',
      request,
      metadata || {},
      methodDescriptor_ApiService_HandleAgentExecutionRequestStream);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.SummaryOfProcessMessage>}
 */
const methodDescriptor_ApiService_GetSummaryOfProcess = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetSummaryOfProcess',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.SummaryOfProcessMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SummaryOfProcessMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SummaryOfProcessMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SummaryOfProcessMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getSummaryOfProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetSummaryOfProcess',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetSummaryOfProcess,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SummaryOfProcessMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getSummaryOfProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetSummaryOfProcess',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetSummaryOfProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.PlaybookMessage,
 *   !proto.com.locusive.api_service.v1.PlaybookMessage>}
 */
const methodDescriptor_ApiService_UpsertPlaybook = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpsertPlaybook',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.PlaybookMessage,
  proto.com.locusive.api_service.v1.PlaybookMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.PlaybookMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.PlaybookMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.PlaybookMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.PlaybookMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.PlaybookMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.upsertPlaybook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpsertPlaybook',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpsertPlaybook,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.PlaybookMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.PlaybookMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.upsertPlaybook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpsertPlaybook',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpsertPlaybook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.ValidDomainsResponse>}
 */
const methodDescriptor_ApiService_GetValidDomainsForAgent = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetValidDomainsForAgent',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.ValidDomainsResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.ValidDomainsResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.ValidDomainsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.ValidDomainsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getValidDomainsForAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetValidDomainsForAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetValidDomainsForAgent,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.ValidDomainsResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getValidDomainsForAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetValidDomainsForAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetValidDomainsForAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AgentMessage,
 *   !proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 */
const methodDescriptor_ApiService_UpdateAgent = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/UpdateAgent',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AgentMessage,
  proto.com.locusive.api_service.v1.SimpleSuccessResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.AgentMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.SimpleSuccessResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AgentMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.SimpleSuccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.updateAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateAgent,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AgentMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.SimpleSuccessResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.updateAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/UpdateAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_UpdateAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.ContextualRecommendationRequest,
 *   !proto.com.locusive.api_service.v1.ContextualRecommendationsResponse>}
 */
const methodDescriptor_ApiService_GetContextualRecommendations = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetContextualRecommendations',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.ContextualRecommendationRequest,
  proto.com.locusive.api_service.v1.ContextualRecommendationsResponse,
  /**
   * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.ContextualRecommendationsResponse.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.ContextualRecommendationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getContextualRecommendations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetContextualRecommendations',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetContextualRecommendations,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.ContextualRecommendationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.ContextualRecommendationsResponse>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getContextualRecommendations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetContextualRecommendations',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetContextualRecommendations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.SimpleIdRequest,
 *   !proto.com.locusive.api_service.v1.AgentMessage>}
 */
const methodDescriptor_ApiService_GetAgent = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/GetAgent',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.SimpleIdRequest,
  proto.com.locusive.api_service.v1.AgentMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AgentMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AgentMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AgentMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.getAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetAgent,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.SimpleIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AgentMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.getAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/GetAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_GetAgent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.com.locusive.api_service.v1.AgentMessage,
 *   !proto.com.locusive.api_service.v1.AgentMessage>}
 */
const methodDescriptor_ApiService_CreateAgent = new grpc.web.MethodDescriptor(
  '/com.locusive.api_service.v1.ApiService/CreateAgent',
  grpc.web.MethodType.UNARY,
  proto.com.locusive.api_service.v1.AgentMessage,
  proto.com.locusive.api_service.v1.AgentMessage,
  /**
   * @param {!proto.com.locusive.api_service.v1.AgentMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.com.locusive.api_service.v1.AgentMessage.deserializeBinary
);


/**
 * @param {!proto.com.locusive.api_service.v1.AgentMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.com.locusive.api_service.v1.AgentMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.com.locusive.api_service.v1.AgentMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.com.locusive.api_service.v1.ApiServiceClient.prototype.createAgent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateAgent,
      callback);
};


/**
 * @param {!proto.com.locusive.api_service.v1.AgentMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.com.locusive.api_service.v1.AgentMessage>}
 *     Promise that resolves to the response
 */
proto.com.locusive.api_service.v1.ApiServicePromiseClient.prototype.createAgent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/com.locusive.api_service.v1.ApiService/CreateAgent',
      request,
      metadata || {},
      methodDescriptor_ApiService_CreateAgent);
};


module.exports = proto.com.locusive.api_service.v1;

