import {
    AddDocumentToIndexRequest,
    AgentExecutionRequest,
    AgentMessage,
    GetTopDocumentMatchesRequest,
    IndexedDocumentMessage,
    SimpleIdRequest,
    StatusUpdateParameterCollection,
    ContextualRecommendationRequest
} from "../generated/api_service_pb";
import {ApiServiceClient} from "../generated/api_service_grpc_web_pb";


const AgentType = "Embedded"; // Get this from `ChannelType.java`


class EmbeddedAssistantApiClient {

    static log = (message, debugMode = false) => {
        if (process.env.REACT_APP_ENVIRONMENT === "development" || debugMode) {
            console.log(message);
        }
    }

    static error = (message, debugMode = false) => {
        if (process.env.REACT_APP_ENVIRONMENT === "development" || debugMode) {
            console.error(message);
        }
    }

    static agentExecutionRequest = (messages, agentId, statusMessageId, pageContent, debugMode, callback) => {
        if (!messages || messages.length === 0) {
            callback(null);
            return;
        }

        const message = messages?.[messages?.length - 1].getMessage()

        let agentExecutionRequest = new AgentExecutionRequest()
        agentExecutionRequest.setMessage(message);
        agentExecutionRequest.setMessagesList(messages);
        if (pageContent) {
            agentExecutionRequest.setCurrentdocumentcontent(pageContent);
        }

        let statusUpdateParameterCollection = new StatusUpdateParameterCollection();
        statusUpdateParameterCollection.setStatusmessageid(statusMessageId);
        agentExecutionRequest.setStatusupdateparametercollection(statusUpdateParameterCollection);

        let agent = new AgentMessage();
        agent.setId(agentId);
        agent.setType(AgentType);
        agentExecutionRequest.setAgent(agent)

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            EmbeddedAssistantApiClient.log("Sending agent execution request", debugMode);
            const stream = apiServiceClient.handleAgentExecutionRequestStream(agentExecutionRequest, {deadline: null});
            console.log('sending request')


            stream.on('data', (agentExecutionResultMessage) => {
                callback(agentExecutionResultMessage);
            });

            stream.on('error', (err) => {
                EmbeddedAssistantApiClient.error("Error: " + err, debugMode);
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.error("Error: " + err, debugMode);
            callback(null);
        }
    }

    static addDocumentToIndexRequest = (agentId, url, callback) => {
        if (!agentId || !url) {
            callback(null);
            return;
        }

        // create agent message
        const agentMessage = new AgentMessage();
        agentMessage.setId(agentId);
        agentMessage.setType(AgentType);

        // create indexed document message
        const indexedDocument = new IndexedDocumentMessage();
        indexedDocument.setUri(url);

        // create indexed document request
        const addDocumentToIndexRequest = new AddDocumentToIndexRequest();
        addDocumentToIndexRequest.setDocument(indexedDocument);
        addDocumentToIndexRequest.setAgent(agentMessage)

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            apiServiceClient.addDocumentToIndex(addDocumentToIndexRequest, {}, (err, simpleSuccessResponse) => {
                if (err || !simpleSuccessResponse.getSuccess()) {
                    EmbeddedAssistantApiClient.log("Error adding webpage to index", err);
                    callback(false);
                }
                else {
                    EmbeddedAssistantApiClient.log("Successfully added document");
                    callback(simpleSuccessResponse);
                }
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.log("Error: ", err);
            callback(null);
        }

    }

    static getTopDocumentMatchesRequest = (agentId, searchTerm, callback) => {
        if (!agentId || !searchTerm) {
            callback(null);
            return;
        }

        // create agent message
        const agentMessage = new AgentMessage();
        agentMessage.setId(agentId);
        agentMessage.setType(AgentType);

        // create indexed document request
        const getTopDocumentMatchesRequest = new GetTopDocumentMatchesRequest();
        getTopDocumentMatchesRequest.setPrompt(searchTerm);
        getTopDocumentMatchesRequest.setAgent(agentMessage)

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            apiServiceClient.getTopDocumentMatches(getTopDocumentMatchesRequest, {}, (err, getTopDocumentMatchesResponse) => {
                if (err || !getTopDocumentMatchesResponse.getMatchesList()) {
                    EmbeddedAssistantApiClient.error("Error getting top document matches", err);
                    callback(false);
                }
                else {
                    EmbeddedAssistantApiClient.log("Successfully got top document matches");
                    callback(getTopDocumentMatchesResponse);
                }
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.log("Error:", err);
            callback(null);
        }
    }

    static getContextualRecommendations = (agentId, url, debugMode, callback) => {
        if (!agentId) {
            callback(null);
            return;
        }

        // create agent message
        const contextualRecommendationRequest = new ContextualRecommendationRequest();
        contextualRecommendationRequest.setAgentid(agentId);
        contextualRecommendationRequest.setUrl(url);

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            apiServiceClient.getContextualRecommendations(contextualRecommendationRequest, {}, (err, contextualRecommendationsResponse) => {
                if (err || !contextualRecommendationsResponse) {
                    EmbeddedAssistantApiClient.error("Error getting contextual recommendations: " + err, debugMode);
                    callback(null);
                }
                else {
                    EmbeddedAssistantApiClient.log("Successfully got contextual recommendations", debugMode);
                    callback(contextualRecommendationsResponse.getRecommendationsList());
                }
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.log("Error: " + err, debugMode);
            callback(null);
        }
    }

    static getAgent = (id, callback) => {
        if (!id) {
            callback(null);
            return;
        }

        const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
        const simpleIdRequest = new SimpleIdRequest();
        simpleIdRequest.setId(id);

        apiServiceClient.getAgent(simpleIdRequest, {}, (err, agentMessage) => {
            if (err || !agentMessage) {
                callback(null);
            }
            else {
                callback(agentMessage);
            }
        });
    }

    static getSummaryOfProcessRequest = (id, callback) => {
        if (!id) {
            callback(null);
            return;
        }

        const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
        const simpleIdRequest = new SimpleIdRequest();
        simpleIdRequest.setId(id);

        apiServiceClient.getSummaryOfProcess(simpleIdRequest, {}, (err, summaryOfProcessMessage) => {
            if (err || !summaryOfProcessMessage) {
                callback(null);
            }
            else {
                callback(summaryOfProcessMessage);
            }
        });
    }

    static getUsageMetrics = (organizationId, callback) => {
        if (!organizationId) {
            callback(null);
        }

        const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);

        const simpleIdRequest = new SimpleIdRequest();
        simpleIdRequest.setId(organizationId);

        apiServiceClient.getUsageMetrics(simpleIdRequest, {}, (err, usageMetrics) => {
            if (err) {
                console.log("Error getting usage metrics");
                console.log(err);
                callback(null);
            }
            else {
                console.log("Successfully got usage metrics");
                callback(usageMetrics);
            }
        });
    }
}

export default EmbeddedAssistantApiClient;


