// react
import React, {useEffect, useRef, useState} from "react";
// third party
import {v4 as uuidv4} from "uuid";
// mui
import {Autocomplete, Box, IconButton, InputAdornment, TextField} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import './MessageForm.css';

// constants
export const DefaultInput = {label: ""}
export const DefaultPlaceHolder = {label: "Message..."}
export const DefaultOptions = [{label: "No options available"}]


function MessageForm({handleSubmit, options=DefaultOptions, disableMessages}) {
    const [userMessage, setUserMessage] = useState(DefaultInput);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!disableMessages && inputRef.current) {
            inputRef.current.focus();
        }
    }, [disableMessages]);

    function handleSelectChange(event, value = {}) {
        if (value && value?.label) {
            setUserMessage({
                ...userMessage,
                label: value?.label + " "
            })
        }
        else {
            setUserMessage(DefaultInput)
        }
    }

    function handleTextFieldChange(event) {
        if (event?.target?.value) {
            setUserMessage({
                ...userMessage,
                label: event?.target?.value
            });
        } else {
            setUserMessage(DefaultInput)
        }
    }

    function handleOnSubmit(event) {
        event.preventDefault();
        const message = userMessage?.label
        if (message && message?.trim()?.length !== 0 &&  message?.length >= 1) {
            handleSubmit(message)
        }

        setUserMessage(DefaultInput)
    }

    // Handle key down events for Enter and Shift+Enter
    function handleKeyDown(event) {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault(); // Prevent adding a new line
            handleOnSubmit(event);  // Submit the form
        }
        // If Shift+Enter, allow the default behavior (adding a new line)
    }

    return (
        <Box
            component="form"
            display="flex"
            alignItems="center"
            id={"locusive-message-container"}
            p={1}
            // TODO: prevent user from submitting multiple messages in a row/when assistant is working?
            onSubmit={handleOnSubmit}
        >
            <Autocomplete
                fullWidth
                disabled={disableMessages}
                clearOnEscape={true}
                freeSolo={true}
                selectOnFocus={true}
                clearOnBlur
                handleHomeEndKeys
                id={"locusive-message-autocomplete"}
                value={userMessage}
                // open={(optionsList.includes(userMessage?.label) || userMessage?.label?.startsWith("/"))}
                open={(userMessage?.label?.startsWith("/"))}
                disablePortal={true}
                onChange={(event, value) => (
                    handleSelectChange(event, value)
                )}
                options={options ? options : DefaultOptions}
                getOptionLabel={(option) => (
                    option?.label
                        ? option?.label
                        : DefaultInput && DefaultInput?.label
                )}
                renderOption={(props, option) => {
                    const {key, ...otherProps} = props;
                    return (
                        <Box
                            {...otherProps}
                            key={uuidv4()}
                            style={{display: "flex", flexFlow: "row nowrap", alignItems: "flex-start"}}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    flexFlow: "row nowrap",
                                    justifyContent: "center",
                                    alignSelf: "stretch",
                                    padding: "0 0.50rem 0 0"
                                }}
                            >
                                {option?.icon}
                            </Box>
                            <Box style={{display: "flex", flexFlow: "column nowrap", alignItems: "flex-start"}}>
                                {option?.title}
                                {option?.subtitle}
                            </Box>
                        </Box>
                    )
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={(event) => handleTextFieldChange(event)}
                        fullWidth
                        // TODO: address multiline text area warning
                        multiline
                        maxRows={5}
                        placeholder={DefaultPlaceHolder?.label}
                        variant="outlined"
                        size="small"
                        onKeyDown={handleKeyDown}
                        sx={{"& fieldset": {border: "none"}}}
                        id={"locusive-message-input"}
                        InputProps={{
                            ...params.InputProps,
                            inputRef: inputRef,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        role={"button"}
                                        aria-label="Send Message"
                                        name="send"
                                        id={"locusive-send-button"}
                                        type="submit"
                                        onClick={handleOnSubmit}
                                        disabled={disableMessages}>
                                        <SendIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {paddingRight: 10}
                        }}
                    />
                )}
            />
        </Box>
    );
}

export default MessageForm;
