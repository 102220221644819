import React from 'react';
import ReactDOM from 'react-dom/client';
import EmbeddedAssistantChat from "./components/EmbeddedAssistantChat/EmbeddedAssistantChat";
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import './index.css';

const theme = createTheme({
    typography: {
        fontFamily:
            "Inter, sans-serif",
        h3: {
            fontSize: "19px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "#211F26"
        },
        body1: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#211F26"
        },
        body2: {
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#65636D"
        }
    },
});

function init(config) {
    const { containerId, channelInstanceId, debugMode } = config;

    // Render the app into the specified container element
    const rootElement = document.getElementById(containerId);

    if (rootElement) {
        const root = ReactDOM.createRoot(rootElement);
        root.render(
            <ThemeProvider theme={theme}>
                <EmbeddedAssistantChat
                    channelInstanceId={channelInstanceId}
                    debugMode={debugMode}
                />
            </ThemeProvider>
        );
    }
    else {
        console.error(`Container element with id '${containerId}' not found.`);
    }

    // Report web vitals
    reportWebVitals();
}

if (!window.LocusiveAssistant) {
    window.LocusiveAssistant = {};
}

window.LocusiveAssistant.init = init;

export { init };