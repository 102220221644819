import {
    AddDocumentToIndexRequest,
    AgentExecutionRequest,
    ChannelInstanceMessage,
    GetTopDocumentMatchesRequest,
    IndexedDocumentMessage,
    SimpleIdRequest,
    StatusUpdateParameterCollection,
    ContextualRecommendationRequest
} from "../generated/api_service_pb";
import {ApiServiceClient} from "../generated/api_service_grpc_web_pb";


const ChannelType = "Embedded"; // Get this from `ChannelType.java`


class EmbeddedAssistantApiClient {

    static log = (message, debugMode = false) => {
        if (process.env.REACT_APP_ENVIRONMENT === "development" || debugMode) {
            console.log(message);
        }
    }

    static error = (message, debugMode = false) => {
        if (process.env.REACT_APP_ENVIRONMENT === "development" || debugMode) {
            console.error(message);
        }
    }

    static agentExecutionRequest = (messages, channelInstanceId, statusMessageId, pageContent, debugMode, callback) => {
        if (!messages || messages.length === 0) {
            callback(null);
            return;
        }

        const message = messages?.[messages?.length - 1].getMessage()

        let agentExecutionRequest = new AgentExecutionRequest()
        agentExecutionRequest.setMessage(message);
        agentExecutionRequest.setMessagesList(messages);
        if (pageContent) {
            agentExecutionRequest.setCurrentdocumentcontent(pageContent);
        }

        let statusUpdateParameterCollection = new StatusUpdateParameterCollection();
        statusUpdateParameterCollection.setStatusmessageid(statusMessageId);
        agentExecutionRequest.setStatusupdateparametercollection(statusUpdateParameterCollection);

        let channelInstance = new ChannelInstanceMessage();
        channelInstance.setId(channelInstanceId);
        channelInstance.setType(ChannelType);
        agentExecutionRequest.setChannelinstance(channelInstance)

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            EmbeddedAssistantApiClient.log("Sending agent execution request", debugMode);
            const stream = apiServiceClient.handleAgentExecutionRequestStream(agentExecutionRequest, {deadline: null});

            stream.on('data', (agentExecutionResultMessage) => {
                callback(agentExecutionResultMessage);
            });

            stream.on('error', (err) => {
                EmbeddedAssistantApiClient.error("Error: " + err, debugMode);
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.error("Error: " + err, debugMode);
            callback(null);
        }
    }

    static addDocumentToIndexRequest = (channelInstanceId, url, callback) => {
        if (!channelInstanceId || !url) {
            callback(null);
            return;
        }

        // create channel instance message
        const channelInstanceMessage = new ChannelInstanceMessage();
        channelInstanceMessage.setId(channelInstanceId);
        channelInstanceMessage.setType(ChannelType);

        // create indexed document message
        const indexedDocument = new IndexedDocumentMessage();
        indexedDocument.setUri(url);

        // create indexed document request
        const addDocumentToIndexRequest = new AddDocumentToIndexRequest();
        addDocumentToIndexRequest.setDocument(indexedDocument);
        addDocumentToIndexRequest.setChannelinstance(channelInstanceMessage)

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            apiServiceClient.addDocumentToIndex(addDocumentToIndexRequest, {}, (err, simpleSuccessResponse) => {
                if (err || !simpleSuccessResponse.getSuccess()) {
                    EmbeddedAssistantApiClient.log("Error adding webpage to index", err);
                    callback(false);
                }
                else {
                    EmbeddedAssistantApiClient.log("Successfully added document");
                    callback(simpleSuccessResponse);
                }
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.log("Error: ", err);
            callback(null);
        }

    }

    static getTopDocumentMatchesRequest = (channelInstanceId, searchTerm, callback) => {
        if (!channelInstanceId || !searchTerm) {
            callback(null);
            return;
        }

        // create channel instance message
        const channelInstanceMessage = new ChannelInstanceMessage();
        channelInstanceMessage.setId(channelInstanceId);
        channelInstanceMessage.setType(ChannelType);

        // create indexed document request
        const getTopDocumentMatchesRequest = new GetTopDocumentMatchesRequest();
        getTopDocumentMatchesRequest.setPrompt(searchTerm);
        getTopDocumentMatchesRequest.setChannelinstance(channelInstanceMessage)

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            apiServiceClient.getTopDocumentMatches(getTopDocumentMatchesRequest, {}, (err, getTopDocumentMatchesResponse) => {
                if (err || !getTopDocumentMatchesResponse.getMatchesList()) {
                    EmbeddedAssistantApiClient.error("Error getting top document matches", err);
                    callback(false);
                }
                else {
                    EmbeddedAssistantApiClient.log("Successfully got top document matches");
                    callback(getTopDocumentMatchesResponse);
                }
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.log("Error:", err);
            callback(null);
        }
    }

    static getContextualRecommendations = (channelInstanceId, url, debugMode, callback) => {
        if (!channelInstanceId) {
            callback(null);
            return;
        }

        // create channel instance message
        const contextualRecommendationRequest = new ContextualRecommendationRequest();
        contextualRecommendationRequest.setChannelinstanceid(channelInstanceId);
        contextualRecommendationRequest.setUrl(url);

        try {
            const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
            apiServiceClient.getContextualRecommendations(contextualRecommendationRequest, {}, (err, contextualRecommendationsResponse) => {
                if (err || !contextualRecommendationsResponse) {
                    EmbeddedAssistantApiClient.error("Error getting contextual recommendations: " + err, debugMode);
                    callback(null);
                }
                else {
                    EmbeddedAssistantApiClient.log("Successfully got contextual recommendations", debugMode);
                    callback(contextualRecommendationsResponse.getRecommendationsList());
                }
            });
        }
        catch (err) {
            EmbeddedAssistantApiClient.log("Error: " + err, debugMode);
            callback(null);
        }
    }

    static getChannelInstance = (id, callback) => {
        if (!id) {
            callback(null);
            return;
        }

        const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
        const simpleIdRequest = new SimpleIdRequest();
        simpleIdRequest.setId(id);

        apiServiceClient.getChannelInstance(simpleIdRequest, {}, (err, channelInstanceMessage) => {
            if (err || !channelInstanceMessage) {
                callback(null);
            }
            else {
                callback(channelInstanceMessage);
            }
        });
    }

    static getSummaryOfProcessRequest = (id, callback) => {
        if (!id) {
            callback(null);
            return;
        }

        const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
        const simpleIdRequest = new SimpleIdRequest();
        simpleIdRequest.setId(id);

        apiServiceClient.getSummaryOfProcess(simpleIdRequest, {}, (err, summaryOfProcessMessage) => {
            if (err || !summaryOfProcessMessage) {
                callback(null);
            }
            else {
                callback(summaryOfProcessMessage);
            }
        });
    }

    static getValidDomainsForChannelInstance = (id, debugMode, callback) => {
        if (!id) {
            callback(null);
            return;
        }

        const apiServiceClient = new ApiServiceClient(process.env.REACT_APP_ENVOY_URL);
        const simpleIdRequest = new SimpleIdRequest();
        simpleIdRequest.setId(id);

        apiServiceClient.getValidDomainsForChannelInstance(simpleIdRequest, {}, (err, validDomainsMessage) => {
            if (err || !validDomainsMessage) {
                EmbeddedAssistantApiClient.error("Error getting valid domains for channel instance: " + err, debugMode);
                callback(null);
            }
            else {
                callback(validDomainsMessage.getDomainsList());
            }
        });
    }
}

export default EmbeddedAssistantApiClient;


