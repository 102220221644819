import {Typography} from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


export const AddDocumentCommand = "/add_document"
export const FindCommand = "/find"
export const HelpCommand = "/help"

export const AddDocumentCommandTitle = "/add_document url"
export const FindCommandTitle = "/find keywords"
export const HelpCommandTitle = "/help"

export const AddDocumentCommandSubTitle = "Add a new trusted source to your document library"
export const FindCommandSubTitle = "Search across your knowledge base for a term"
export const HelpCommandSubTitle = "Get help with the Locusive Assistant"

// export const SlashCommandOptionsList = [AddDocumentCommand, FindCommand, HelpCommand]

export const SlashCommandOptions = [
    {
        label: AddDocumentCommand,
        title: <Typography component="div" align="left" variant="subtitle2">{AddDocumentCommandTitle}</Typography>,
        subtitle: <Typography component="div" align="left" variant="caption">{AddDocumentCommandSubTitle}</Typography>,
        icon: <NoteAddOutlinedIcon color="primary" />
    },
    {
        label: FindCommand,
        title: <Typography component="div" align="left" variant="subtitle2">{FindCommandTitle}</Typography>,
        subtitle: <Typography component="div" align="left" variant="caption">{FindCommandSubTitle}</Typography>,
        icon: <SearchOutlinedIcon color="primary" />
    },
    {
        label: HelpCommand,
        title: <Typography component="div" align="left" variant="subtitle2">{HelpCommandTitle}</Typography>,
        subtitle: <Typography component="div" align="left" variant="caption">{HelpCommandSubTitle}</Typography>,
        icon: <HelpOutlineOutlinedIcon color="primary" />
    }
]

export const HelpText = `
Hey there, sorry to see you're having problems! Here are a few tips that might help:

* **If you haven't added your OpenAI API Key yet,** I won't be able to respond to your requests. You can add your key at https://app.locusive.com
* **If you haven't connected any data sources yet,** I'll answer your questions using my built-in knowledge or by searching the web
* **If I'm responding slowly,** it might be because you're using a free or basic ChatGPT account. I work best with ChatGPT Plus accounts.

Here are the slash commands that I support:

* **/add_document <url>**: This will add a new public webpage or PDF to your knowledge base for me to search.
* **/find <keywords>**: This will search your knowledge base for the given search term and return the most relevant results.
* **/help**: Get help with using the Locusive Assistant.

If this didn't answer your question, you can also visit our help center at https://help.locusive.com.
`

export const InvalidAddDocumentUrlText = "Please enter a valid url"


